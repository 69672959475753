.assets {
    display: block;
    margin-top: 70px;

    .page-content {
        width: 50%;
    }

    @include screen-sm() {
        .page-content {
            width: 90%;
        }
    }

    .assets-container {
        text-align: center;
        padding-bottom: 40px;
    }

    .container-cards {
        display: flex; 
        justify-content: center; 
        width: 100%;

        @include screen-sm() {
            display: grid;
        }
    }


    .full-iceing {
        background-color: #fff;
        margin: 0;
        border-radius: 30px 30px 30px 30px;
        border: 1px solid #e8e6e6;
        background: #fff;
        box-shadow: 0px 1px 1px 0px #d0d0d0;
    }

    .yellow-title {
        color: #cac8c8;
        font-size: 36px;
    }



    /* Card styles */

    .card-border {
        border-radius: 10px;
        max-width: 200px !important;
        margin: 0 auto;

        @include screen-sm() {
            margin-top: 10px;
        }
    }

    .card {
        padding: 25px 25px 8px 25px;
        border-left: 1px solid #dbdbdb;
        border-right: 1px solid #dbdbdb;
        border-top: 1px solid #dbdbdb;
        border-radius: 10px 10px 0px 0px;
        max-width: 170px;

        .card-body {
            height: 30%;
            padding: 0px;
        }

        .btn {
            border-radius: 15px;
            height: 31px;
            padding: 5px 30px;
            width: 100%;
            max-width: 200px;

        }

        .card-text {
            font-size: 13px;
            color: #686666;
        }

        .fa-caret-down {
            color: #fff;
            margin-left: 3px;
        }


        .card-purchase {
            width: 14rem;
            text-align: center;
        }

        .text-muted {
            margin: 0px 0px 13px 0px;
        }

        h5 {
            font-size: 30px;
            font-weight: bold;
        }

        svg {
            width: 27px;
            height: 100%;
            margin-right: 2px;
        }

        p {
            margin: 0;
        }

        .card-title {
            display: inline;
        }

        .card-text-fiat {
            font-weight: lighter;
            display: flex;
            font-size: 10px;
            align-items: center;
            color: #999797;
            justify-content: center;
            padding: 8px 30px;
        }

        .container-price {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .mbtc-icon {
            color: #fff;
            width: 59px;
            height: 19px;
            background-color: #f06;
            border-radius: 32%;
            font-size: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 1px 4px 1px 4px;
            margin: 0px 0px 0px 5px;
        }

        .big-amount {
            font-weight: 500;
            color: #4a4a4a;
            font-size: 1.85rem;
        }
    }

    .js-asset-payment {

        .media-body {
            width: inherit;
        }

        .media-body p {
            text-align: left;
            font-size: 0.8rem !important;
            margin: 7px 54px 2px 3px;
            white-space: break-spaces;
            padding-bottom: 0px;
        }
    }

    .btn-purchase {
        background-color: #f06;
        width: 100%;
        border-radius: 0px 0px 10px 10px;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        font-weight: bold;

        svg {
            margin-right: 5px;
        }
    }

    .btn-purchase:hover {
        background-color: rgba(225, 12, 82, 0.6) !important;
    }

}