html {
    font-size: 87%;
    text-size-adjust: 100%;
}

body {
    font-size: 1.5rem;
    font-family: $font-family-1;
    font-weight: $font-normal;
    color: $color-font-base;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-kerning: normal;
    //font-variant-ligatures: common-ligatures contextual;
    font-feature-settings: 'kern', 'liga', 'clig', 'calt';
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: $font-family-2;
    font-weight: $font-normal;
    color: $color-font-heading;
}

a {
    color: $color-link;

    &:hover, &:focus {
        color: $color-link-hover;
    }
}

.text-highlight {
    color: $color-brand-2;
}
