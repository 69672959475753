.profile-card {
    width: 90%;
    margin: 0 auto;
    padding: $spacing-half 0;
    font-family: Be Vietnam pro;

    .city-resident-style {
        height: 22px;
        min-width: 70px;
        border-radius: 13px;
        border: 2px solid #e91e63;
        box-shadow: 1px 2px 2px 2px #efeeee;
        padding: 0px;
        margin: 0px 2px;
        background-color: #fff;
        color: #f06f;
        box-sizing: border-box;
        text-align: center;
        cursor: pointer;
        font-weight: bolder;
    }

    .js-city-resident {
        margin-left: 4px;
    }

    .icon-city-resident {
        display: inline-flex;
        vertical-align: middle;
        height: 22px;

        svg {
            position: relative;
            width: 23px;
            height: 18px;
        }
    }

    &__header {
        display: flex;
        align-content: space-between;
        align-items: center;
        margin-bottom: $spacing;
        text-align: left;

        img {
            width: 100px;
            height: 100px;
            object-fit: cover;
            border-radius: 100%;
        }
    }

    &__name {
        margin-left: $spacing;
        width: calc(100% - 100px);

        h2 {
            font-size: 20px;
            font-weight: $font-bold;
            margin: 0;
            padding: 0;
        }

        h5 {
            color: #999;
            margin-top: 4px;
        }

        h3 {
            font-family: Be Vietnam Pro;
            font-weight: 200;
            font-size: 18px;
        }

        span {
            font-style: italic;
        }
    }

    &__details {
        margin-bottom: $spacing;
        text-align: left;
    }

    &__info {
        display: flex;
        margin-bottom: $spacing-half;

        .info-value {
            width: 62%;
            display: flex;
            flex-wrap: wrap;
            font-size: 13px;
            font-family: Be Vietnam Pro;
        }

        .bold {
            font-weight: $font-bold;
        }

        .sprite-container {
            width: 37.5px;
            height: 23px;
            margin-bottom: 5px;
        }

        .sprite {
            transform: scale(0.5, 0.5) translate(-50%, -50%);
        }

        h5 {
            width: 36%;
            color: #999;
            font-size: 16px;
            margin: 0;
            padding: 0;
            display: flex;
            align-self: center;
            font-family: Be Vietnam pro;
        }
    }

    &__button {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
    }


    @include screen-sm() {
        text-align: center;

        &__header {
            justify-content: center;
        }

        &__name {
            width: auto;
        }

        .btn {
            float: none;
        }
    }
}
