.sprite-container {
    width: 63px;
    height: 37px;
    margin-right: 4px 0px;
}

.sprite {
    background-image: url('../images/sprites/flag-sprite2.png');
    background-repeat: no-repeat;
    display: inline-block;
    position: relative;
    margin: 0;
    padding: 0;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.75);
}

.sprite2-ML-Flag-sprite-13 {
    width: 63px;
    height: 37px;
    background-position: -5px -5px;
}

.sprite2-afghanistan {
    width: 63px;
    height: 37px;
    background-position: -96px -10px;
}

.sprite2-albania {
    width: 63px;
    height: 37px;
    background-position: -180px -8px;
}

.sprite2-algeria {
    width: 63px;
    height: 37px;
    background-position: -260px -5px;
}

.sprite2-ambassador {
    width: 63px;
    height: 37px;
    background-position: -265px -8px;
}

.sprite2-andorra {
    width: 63px;
    height: 37px;
    background-position: -435px -9px; /* 5 y 4 */
}

.sprite2-angola {
    width: 63px;
    height: 37px;
    background-position: -523px -9px;
}

.sprite2-antigua_barbuda {
    width: 63px;
    height: 37px;
    background-position: -605px -12px;
}

.sprite2-aramaic {
    width: 63px;
    height: 37px;
    background-position: -691px -8px;
}

.sprite2-argentina {
    width: 63px;
    height: 37px;
    background-position: -777px -10px;
}

.sprite2-armenia {
    width: 63px;
    height: 37px;
    background-position: -859px -10px;
}

.sprite2-associate {
    width: 63px;
    height: 37px;
    background-position: -944px -10px;
}

.sprite2-australia {
    width: 63px;
    height: 37px;
    background-position: -5px -60px;
}

.sprite2-austria {
    width: 63px;
    height: 37px;
    background-position: -92px -65px;
}

.sprite2-azerbaijan {
    width: 63px;
    height: 37px;
    background-position: -181px -65px;
}

.sprite2-bahamas {
    width: 63px;
    height: 37px;
    background-position: -269px -65px;
}

.sprite2-bahrain {
    width: 63px;
    height: 37px;
    background-position: -348px -61px;
}

.sprite2-bangladesh {
    width: 63px;
    height: 37px;
    background-position: -435px -66px;
}

.sprite2-barbados {
    width: 63px;
    height: 37px;
    background-position: -521px -64px;
}

.sprite2-basque {
    width: 63px;
    height: 37px;
    background-position: -607px -66px;
}

.sprite2-belarus {
    width: 63px;
    height: 37px;
    background-position: -689px -70px;
}

.sprite2-belgium {
    width: 63px;
    height: 37px;
    background-position: -776px -61px;
}

.sprite2-belize {
    width: 63px;
    height: 37px;
    background-position: -861px -66px;
}

.sprite2-berber {
    width: 63px;
    height: 37px;
    background-position: -947px -65px;
}

.sprite2-benin {
    width: 63px;
    height: 37px;
    background-position: -7px -120px;
}

.sprite2-bhutan {
    width: 63px;
    height: 37px;
    background-position: -98px -122px;
}

.sprite2-birthday {
    width: 63px;
    height: 37px;
    background-position: -177px -123px;
}

.sprite2-bolivia {
    width: 63px;
    height: 37px;
    background-position: -260px -122px;
}

.sprite2-bosnia {
    width: 63px;
    height: 37px;
    background-position: -353px -117px;
}

.sprite2-botswana {
    width: 63px;
    height: 37px;
    background-position: -430px -121px;
}

.sprite2-brazil {
    width: 63px;
    height: 37px;
    background-position: -520px -121px;
}

.sprite2-brunei {
    width: 63px;
    height: 47px;
    background-position: -608px -117px;
}

.sprite2-bulgaria {
    width: 63px;
    height: 37px;
    background-position: -688px -121px;
}

.sprite2-burkina_faso {
    width: 63px;
    height: 37px;
    background-position: -776px -121px;
}

.sprite2-burundi {
    width: 63px;
    height: 37px;
    background-position: -861px -121px;
}

.sprite2-cambodia {
    width: 63px;
    height: 37px;
    background-position: -946px -121px;
}

.sprite2-cameroon {
    width: 63px;
    height: 37px;
    background-position: -9px -176px;
}

.sprite2-canada {
    width: 63px;
    height: 37px;
    background-position: -95px -175px;
}

.sprite2-canary {
    width: 63px;
    height: 37px;
    background-position: -181px -176px;
}

.sprite2-cape_verde {
    width: 63px;
    height: 37px;
    background-position: -264px -177px;
}

.sprite2-catalan {
    width: 63px;
    height: 37px;
    background-position: -345px -176px;
}

.sprite2-central_africa_republic {
    width: 63px;
    height: 37px;
    background-position: -435px -176px;
}

.sprite2-chad {
    width: 63px;
    height: 37px;
    background-position: -520px -173px;
}

.sprite2-chile {
    width: 63px;
    height: 37px;
    background-position: -685px -177px;
}

.sprite2-china {
    width: 63px;
    height: 37px;
    background-position: -772px -173px;
}

.sprite2-cologne {
    width: 63px;
    height: 37px;
    background-position: -864px -177px;
}

.sprite2-colombia {
    width: 63px;
    height: 37px;
    background-position: -944px -178px;
}

.sprite2-comoros {
    width: 63px;
    height: 37px;
    background-position: -5px -234px;
}

.sprite2-congo {
    width: 63px;
    height: 37px;
    background-position: -90px -235px;
}

.sprite2-cook_island {
    width: 63px;
    height: 37px;
    background-position: -181px -231px;
}

.sprite2-costa_rica {
    width: 63px;
    height: 37px;
    background-position: -260px -234px;
}

.sprite2-cote_divoire {
    width: 63px;
    height: 37px;
    background-position: -350px -229px;
}

.sprite2-crimea {
    width: 63px;
    height: 37px;
    background-position: -430px -234px;
}

.sprite2-croatia {
    width: 63px;
    height: 37px;
    background-position: -521px -233px;
}

.sprite2-cuba {
    width: 63px;
    height: 37px;
    background-position: -603px -234px;
}

.sprite2-curacao {
    width: 63px;
    height: 37px;
    background-position: -688px -234px;
}

.sprite2-cyprus {
    width: 63px;
    height: 37px;
    background-position: -778px -236px;
}

.sprite2-czech {
    width: 63px;
    height: 37px;
    background-position: -859px -233px;
}

.sprite2-denmark {
    width: 63px;
    height: 37px;
    background-position: -944px -234px;
}

.sprite2-djibouti {
    width: 63px;
    height: 37px;
    background-position: -10px -289px;
}

.sprite2-dominica {
    width: 63px;
    height: 37px;
    background-position: -97px -290px;
}

.sprite2-dominican_republic {
    width: 63px;
    height: 37px;
    background-position: -181px -289px;
}

.sprite2-east_timor {
    width: 63px;
    height: 37px;
    background-position: -263px -289px;
}

.sprite2-ecuador {
    width: 63px;
    height: 37px;
    background-position: -352px -291px;
}

.sprite2-egypt {
    width: 63px;
    height: 37px;
    background-position: -437px -289px;
}

.sprite2-el_salvador {
    width: 63px;
    height: 37px;
    background-position: -522px -290px;
}

.sprite2-england {
    width: 63px;
    height: 37px;
    background-position: -607px -289px;
}

.sprite2-equatorial_guinea {
    width: 63px;
    height: 37px;
    background-position: -685px -289px;
}

.sprite2-eritrea {
    width: 63px;
    height: 37px;
    background-position: -779px -290px;
}

.sprite2-esperanto {
    width: 63px;
    height: 37px;
    background-position: -855px -289px;
}

.sprite2-estonia {
    width: 63px;
    height: 37px;
    background-position: -942px -290px;
}

.sprite2-ethiopia {
    width: 63px;
    height: 37px;
    background-position: -11px -345px;
}

.sprite2-faroe_island {
    width: 63px;
    height: 37px;
    background-position: -91px -345px;
}

.sprite2-fiji {
    width: 63px;
    height: 37px;
    background-position: -183px -342px;
}

.sprite2-finland {
    width: 63px;
    height: 37px;
    background-position: -264px -345px;
}

.sprite2-flemish {
    width: 63px;
    height: 37px;
    background-position: -352px -344px;
}

.sprite2-france {
    width: 63px;
    height: 37px;
    background-position: -435px -342px;
}

.sprite2-french_polynesia {
    width: 63px;
    height: 37px;
    background-position: -522px -346px;
}

.sprite2-gabon {
    width: 63px;
    height: 37px;
    background-position: -602px -346px;
}

.sprite2-galicia {
    width: 63px;
    height: 37px;
    background-position: -692px -344px;
}

.sprite2-gambia {
    width: 63px;
    height: 37px;
    background-position: -771px -346px;
}

.sprite2-georgia {
    width: 63px;
    height: 37px;
    background-position: -862px -345px;
}

.sprite2-germany {
    width: 63px;
    height: 37px;
    background-position: -940px -346px;
}

.sprite2-ghana {
    width: 63px;
    height: 37px;
    background-position: -11px -401px;
}

.sprite2-greece {
    width: 63px;
    height: 37px;
    background-position: -93px -401px;
}

.sprite2-greenland {
    width: 63px;
    height: 37px;
    background-position: -175px -402px;
}

.sprite2-grenada {
    width: 63px;
    height: 37px;
    background-position: -265px -401px;
}

.sprite2-guadaloupe {
    width: 63px;
    height: 37px;
    background-position: -351px -401px;
}

.sprite2-guatemala {
    width: 63px;
    height: 37px;
    background-position: -436px -401px;
}

.sprite2-guinea {
    width: 63px;
    height: 37px;
    background-position: -520px -397px;
}

.sprite2-guinea_bissau {
    width: 63px;
    height: 37px;
    background-position: -605px -402px;
}

.sprite2-guyana {
    width: 63px;
    height: 37px;
    background-position: -695px -401px;
}

.sprite2-haiti {
    width: 63px;
    height: 37px;
    background-position: -777px -401px;
}

.sprite2-hawaii {
    width: 63px;
    height: 37px;
    background-image: url('https://mundolingo.org/images/sprites/hawaii.png')!important;
    background-repeat: no-repeat;
    background-size: 64px 35px!important;
    display: inline-block;
    position: relative;
    margin: 0;
    padding: 0;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.75);
}

.sprite2-honduras {
    width: 63px;
    height: 37px;
    background-position: -947px -401px;
}

.sprite2-honk_kong {
    width: 63px;
    height: 37px;
    background-position: -11px -458px;
}

.sprite2-hungary {
    width: 63px;
    height: 37px;
    background-position: -90px -458px;
}

.sprite2-iceland {
    width: 63px;
    height: 37px;
    background-position: -175px -458px;
}

.sprite2-india {
    width: 63px;
    height: 37px;
    background-position: -266px -458px;
}

.sprite2-indonesia {
    width: 63px;
    height: 37px;
    background-position: -347px -458px;
}

.sprite2-iran {
    width: 63px;
    height: 37px;
    background-position: -437px -457px;
}

.sprite2-iraq {
    width: 63px;
    height: 37px;
    background-position: -522px -457px;
}

.sprite2-ireland {
    width: 63px;
    height: 37px;
    background-position: -606px -454px;
}

.sprite2-israel {
    width: 63px;
    height: 37px;
    background-position: -692px -457px;
}

.sprite2-italy {
    width: 63px;
    height: 37px;
    background-position: -775px -453px;
}


.sprite2-jamaica {
    width: 63px;
    height: 37px;
    background-position: -861px -457px;
}

.sprite2-japan {
    width: 63px;
    height: 37px;
    background-position: -948px -458px;
}

.sprite2-jordan {
    width: 63px;
    height: 37px;
    background-position: -11px -513px;
}

.sprite2-kanak {
    width: 63px;
    height: 37px;
    background-position: -93px -514px;
}

.sprite2-kazakhstan {
    width: 63px;
    height: 37px;
    background-position: -183px -515px;
}

.sprite2-kenya {
    width: 63px;
    height: 37px;
    background-position: -267px -515px;
}

.sprite2-kiribati {
    width: 63px;
    height: 37px;
    background-position: -351px -515px;
}

.sprite2-kosovo {
    width: 63px;
    height: 37px;
    background-position: -437px -512px;
}

.sprite2-kurdistan {
    width: 63px;
    height: 37px;
    background-position: -522px -514px;
}

.sprite2-kuwait {
    width: 63px;
    height: 37px;
    background-position: -604px -513px;
}

.sprite2-kyrgyzstan {
    width: 63px;
    height: 37px;
    background-position: -691px -514px;
}

.sprite2-laos {
    width: 63px;
    height: 37px;
    background-position: -776px -515px;
}

.sprite2-latvia {
    width: 63px;
    height: 37px;
    background-position: -857px -514px;
}

.sprite2-lebanon {
    width: 63px;
    height: 37px;
    background-position: -947px -513px;
}

.sprite2-lesotho {
    width: 63px;
    height: 37px;
    background-position: -10px -569px;
}

.sprite2-liberia {
    width: 63px;
    height: 37px;
    background-position: -90px -565px;
}

.sprite2-libya {
    width: 63px;
    height: 37px;
    background-position: -181px -571px;
}

.sprite2-liechtenstein {
    width: 63px;
    height: 37px;
    background-position: -260px -569px;
}

.sprite2-lithuania {
    width: 63px;
    height: 37px;
    background-position: -345px -571px;
}

.sprite2-luxembourg {
    width: 63px;
    height: 37px;
    background-position: -431px -570px;
}

.sprite2-macao {
    width: 63px;
    height: 37px;
    background-position: -520px -572px;
}

.sprite2-macedonia {
    width: 63px;
    height: 37px;
    background-position: -606px -569px;
}

.sprite2-madagascar {
    width: 63px;
    height: 37px;
    background-position: -690px -569px;
}

.sprite2-malawi {
    width: 63px;
    height: 37px;
    background-position: -778px -571px;
}

.sprite2-malaysia {
    width: 63px;
    height: 37px;
    background-position: -862px -570px;
}

.sprite2-maldives {
    width: 63px;
    height: 37px;
    background-position: -945px -570px;
}

.sprite2-mali {
    width: 63px;
    height: 37px;
    background-position: -10px -621px;
}

.sprite2-malta {
    width: 63px;
    height: 37px;
    background-position: -97px -621px;
}

.sprite2-maori {
    width: 63px;
    height: 37px;
    background-position: -179px -625px;
}


.sprite2-marshal_islands {
    width: 63px;
    height: 45px;
    background-position: -265px -622px;
}

.sprite2-martinique {
    width: 63px;
    height: 37px;
    background-position: -352px -626px;
}

.sprite2-mauritania {
    width: 63px;
    height: 45px;
    background-position: -436px -621px;
}

.sprite2-mauritius {
    width: 63px;
    height: 37px;
    background-position: -515px -627px;
}

.sprite2-mexico {
    width: 63px;
    height: 37px;
    background-position: -605px -624px;
}

.sprite2-micronesia_federated {
    width: 63px;
    height: 37px;
    background-position: -691px -626px;
}

.sprite2-moldova {
    width: 63px;
    height: 37px;
    background-position: -775px -625px;
}

.sprite2-monaco {
    width: 63px;
    height: 37px;
    background-position: -856px -625px;
}

.sprite2-mongolia {
    width: 63px;
    height: 37px;
    background-position: -946px -626px;
}

.sprite2-montenegro {
    width: 63px;
    height: 37px;
    background-position: -11px -681px;
}

.sprite2-morocco {
    width: 63px;
    height: 37px;
    background-position: -96px -682px;
}

.sprite2-mozambique {
    width: 63px;
    height: 37px;
    background-position: -178px -682px;
}

.sprite2-myanmar {
    width: 63px;
    height: 37px;
    background-position: -266px -682px;
}

.sprite2-namibia {
    width: 63px;
    height: 37px;
    background-position: -351px -681px;
}

.sprite2-nauru {
    width: 63px;
    height: 37px;
    background-position: -434px -684px;
}

.sprite2-nepal {
    width: 63px;
    height: 37px;
    background-position: -522px -683px;
}

.sprite2-netherlands {
    width: 63px;
    height: 37px;
    background-position: -601px -681px;
}

.sprite2-new-zealand {
    width: 63px;
    height: 37px;
    background-position: -688px -679px;
}

.sprite2-nicaragua {
    width: 63px;
    height: 37px;
    background-position: -777px -682px;
}

.sprite2-niger {
    width: 63px;
    height: 37px;
    background-position: -862px -681px;
}

.sprite2-nigeria {
    width: 63px;
    height: 37px;
    background-position: -946px -679px;
}

.sprite2-north_korea {
    width: 63px;
    height: 37px;
    background-position: -7px -738px;
}

.sprite2-norway {
    width: 63px;
    height: 37px;
    background-position: -91px -738px;
}

.sprite2-oman {
    width: 63px;
    height: 37px;
    background-position: -178px -737px;
}

.sprite2-pakistan {
    width: 63px;
    height: 37px;
    background-position: -265px -739px;
}

.sprite2-palau {
    width: 63px;
    height: 37px;
    background-position: -348px -738px;
}

.sprite2-palestine {
    width: 63px;
    height: 37px;
    background-position: -433px -738px;
}

.sprite2-panama {
    width: 63px;
    height: 37px;
    background-position: -522px -738px;
}

.sprite2-papua_new_guinea {
    width: 63px;
    height: 37px;
    background-position: -603px -738px;
}

.sprite2-paraguay {
    width: 63px;
    height: 37px;
    background-position: -691px -738px;
}

.sprite2-peru {
    width: 63px;
    height: 37px;
    background-position: -776px -736px;
}

.sprite2-philippines {
    width: 63px;
    height: 37px;
    background-position: -860px -737px;
}

.sprite2-poland {
    width: 63px;
    height: 37px;
    background-position: -942px -737px;
}

.sprite2-portugal {
    width: 63px;
    height: 37px;
    background-position: -5px -794px;
}

.sprite2-puerto_rico {
    width: 63px;
    height: 37px;
    background-position: -93px -794px;
}

.sprite2-qatar {
    width: 63px;
    height: 37px;
    background-position: -178px -789px;
}

.sprite2-quebec {
    width: 63px;
    height: 37px;
    background-position: -265px -794px;
}

.sprite2-romania {
    width: 63px;
    height: 37px;
    background-position: -350px -789px;
}

.sprite2-russia {
    width: 63px;
    height: 37px;
    background-position: -430px -793px;
}

.sprite2-rwanda {
    width: 63px;
    height: 37px;
    background-position: -527px -796px;
}

.sprite2-saint_kitts_nevis {
    width: 63px;
    height: 37px;
    background-position: -600px -797px;
}

.sprite2-saint_lucia {
    width: 63px;
    height: 37px;
    background-position: -690px -799px;
}

.sprite2-samoa {
    width: 63px;
    height: 37px;
    background-position: -779px -795px;
}

.sprite2-san_marino {
    width: 63px;
    height: 37px;
    background-position: -862px -793px;
}

.sprite2-sao_tome_and_principe {
    width: 63px;
    height: 37px;
    background-position: -946px -794px;
}

.sprite2-saudi_arabia {
    width: 63px;
    height: 37px;
    background-position: -10px -850px;
}

.sprite2-scotland {
    width: 63px;
    height: 37px;
    background-position: -96px -850px;
}

.sprite2-senegal {
    width: 63px;
    height: 37px;
    background-position: -180px -849px;
}

.sprite2-serbia {
    width: 63px;
    height: 37px;
    background-position: -264px -851px;
}

.sprite2-seychelles {
    width: 63px;
    height: 37px;
    background-position: -345px -854px;
}

.sprite2-sierra_leone {
    width: 63px;
    height: 37px;
    background-position: -432px -850px;
}

.sprite2-sign_language {
    width: 63px;
    height: 37px;
    background-position: -521px -851px;
}

.sprite2-singapore {
    width: 63px;
    height: 37px;
    background-position: -602px -850px;
}

.sprite2-slovakia {
    width: 63px;
    height: 37px;
    background-position: -685px -849px;
}

.sprite2-slovenia {
    width: 63px;
    height: 37px;
    background-position: -775px -849px;
}

.sprite2-solomon_islands {
    width: 63px;
    height: 37px;
    background-position: -858px -852px;
}

.sprite2-somalia {
    width: 63px;
    height: 37px;
    background-position: -947px -850px;
}

.sprite2-south_africa {
    width: 63px;
    height: 37px;
    background-position: -13px -907px;
}

.sprite2-south_korea {
    width: 63px;
    height: 37px;
    background-position: -97px -906px;
}

.sprite2-south_sudan {
    width: 63px;
    height: 37px;
    background-position: -182px -906px;
}

.sprite2-st_vincent_grenadines {
    width: 63px;
    height: 37px;
    background-image: url('https://mundolingo.org/images/sprites/st_vincent_grenadines.png')!important;
    background-repeat: no-repeat;
    background-size: 64px 35px!important;
    display: inline-block;
    position: relative;
    margin: 0;
    padding: 0;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.75);
}

.sprite2-spain {
    width: 63px;
    height: 37px;
    background-position: -265px -907px;
}

.sprite2-sri_lanka {
    width: 63px;
    height: 37px;
    background-position: -350px -906px;
}

.sprite2-sudan {
    width: 63px;
    height: 37px;
    background-position: -434px -906px;
}

.sprite2-suriname {
    width: 63px;
    height: 37px;
    background-position: -520px -906px;
}

.sprite2-swaziland {
    width: 63px;
    height: 37px;
    background-position: -606px -906px;
}

.sprite2-sweden {
    width: 63px;
    height: 37px;
    background-position: -691px -906px;
}

.sprite2-switzerland {
    width: 63px;
    height: 37px;
    background-position: -777px -906px;
}

.sprite2-syria {
    width: 63px;
    height: 37px;
    background-position: -862px -905px;
}

.sprite2-taiwan {
    width: 63px;
    height: 37px;
    background-position: -945px -905px;
}

.sprite2-tajikistan {
    width: 63px;
    height: 37px;
    background-position: -11px -962px;
}

.sprite2-tanzania {
    width: 63px;
    height: 37px;
    background-position: -96px -961px;
}

.sprite2-thailand {
    width: 63px;
    height: 37px;
    background-position: -177px -963px;
}

.sprite2-togo {
    width: 63px;
    height: 37px;
    background-position: -263px -962px;
}

.sprite2-tonga {
    width: 63px;
    height: 37px;
    background-position: -349px -960px;
}

.sprite2-trinidad_tobago {
    width: 63px;
    height: 37px;
    background-position: -433px -957px;
}

.sprite2-tunisia {
    width: 63px;
    height: 37px;
    background-position: -521px -962px;
}

.sprite2-turkey {
    width: 63px;
    height: 37px;
    background-position: -603px -962px;
}

.sprite2-turkmenistan {
    width: 63px;
    height: 37px;
    background-position: -687px -957px;
}

.sprite2-tuvalu {
    width: 63px;
    height: 37px;
    background-position: -776px -957px;
}

.sprite2-uganda {
    width: 63px;
    height: 47px;
    background-position: -860px -958px;
}

.sprite2-ukraine {
    width: 63px;
    height: 37px;
    background-position: -942px -962px;
}

.sprite2-united_arab_emirates {
    width: 63px;
    height: 37px;
    background-position: -1029px -9px;
}

.sprite2-united_kingdom {
    width: 63px;
    height: 37px;
    background-position: -1032px -66px;
}

.sprite2-united_states_of_america {
    width: 63px;
    height: 37px;
    background-position: -1032px -122px;
}

.sprite2-uruguay {
    width: 63px;
    height: 37px;
    background-position: -1029px -175px;
}

.sprite2-uzbekistan {
    width: 63px;
    height: 37px;
    background-position: -1027px -233px;
}

.sprite2-vanuatu {
    width: 63px;
    height: 37px;
    background-position: -1029px -290px;
}

.sprite2-vatican_city {
    width: 63px;
    height: 37px;
    background-position: -1036px -346px;
}

.sprite2-venezuela {
    width: 63px;
    height: 37px;
    background-position: -1032px -401px;
}

.sprite2-vietnam {
    width: 63px;
    height: 37px;
    background-position: -1032px -459px;
}

.sprite2-wales {
    width: 63px;
    height: 37px;
    background-position: -1032px -514px;
}

.sprite2-western_sahara {
    width: 63px;
    height: 37px;
    background-position: -1034px -570px;
}

.sprite2-yemen {
    width: 63px;
    height: 37px;
    background-position: -1029px -626px;
}

.sprite2-yiddish {
    width: 63px;
    height: 37px;
    background-position: -1032px -681px;
}

.sprite2-zambia {
    width: 63px;
    height: 37px;
    background-position: -1038px -733px;
}

.sprite2-zimbabwe {
    width: 63px;
    height: 37px;
    background-position: -1031px -795px;
}
