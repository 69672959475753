.edit-event {
    width: 400px;
    max-width: 100%;
    border-radius: 0;
    border: 1px solid #636363;
    padding: $spacing-half;
    text-align: center;

    &__close {
        position: absolute;
        top: 0;
        right: 5px;
        font-size: 20px;
    }

    &__content {
        margin: $spacing 0;
    }

    .radio {
        display: flex;
        flex-wrap: wrap;

        label {
            width: 70px;
        }
    }

    h4 {
        color: #999;
        margin: 0;
        padding: 0 0 0 $spacing-half;
        font-size: 16px;
        text-align: left;
    }

    h2 {
        font-size: 20px;
        text-align: center;
        font-weight: $font-bold;
        margin-bottom: $spacing;
    }
}

.event {
    position: relative;
    width: 250px;
    margin: $spacing-double $spacing 0 $spacing;
    overflow: hidden;

    &__image {
        width: 100%;
        height: 170px;
        background: #fff;
        transition: 0.5s;
        overflow: hidden;

        img {
            width: 100%;
            height: 170px;
            object-fit: cover;
            transition: 0.5s;
            opacity: 0.85;
        }
    }

    &__actions {
        margin-top: $spacing-half;
        padding: 0px 0px 4px 0px;
        /*border-top: 2px solid $gray2;*/
        display: flex;
        justify-content: space-between;

        a {
            color: $color-brand-1;
            padding: 5px;
        }
    }

    &__controls {
        width: 100%;
        position: absolute;
        top: 0;
        background-color: rgba(50, 50, 50, 0.75);
        text-align: right;

        a {
            display: inline-block;
            padding: 2px;
            margin: 0 5px;
            color: #fff;
            font-size: 20px;
            transition: color 0.25s;
        }

        a:hover {
            color: $gray2;
        }
    }

    .orange-color {
        display: block;
        text-align: center;
        color: $color-brand-3;
    }

    h3, h4 {
        font-size: 16px;
        text-align: center;
        margin: 0;
        padding: 0;
    }

    h3 {
        margin: 10px 0 5px 0;
        font-weight: $font-bold;
    }

    h4 {
        margin-bottom: $spacing-half;
    }

    p {
        margin: 0;
        padding: 0;
        text-align: center;
    }

    &:hover {
        img {
            transform: rotate(5deg) scale(1.3, 1.3);
            opacity: 1;
        }

        .event__controls {
            transform: translateY(0);
        }
    }
}
