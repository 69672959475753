//-------------------------------------//
// GRID

$grid-gutter: 15px;


//-------------------------------------//
// MEDIA QUERIES

$media-lg: 1200px;
$media-md: 992px;
$media-sm: 768px;
$media-xs: 480px;
$media-xxs: 390px;


//-------------------------------------//
// DIMENSIONS

// navmenu height
$height-navmenu: 164px;
$height-navmenu-mobile: 217px;

// navmenu top/bottom padding
$padding-navmenu: 5px;

// navmenu top spacing
$spacing-navmenu-top: 50px;

// border-radius
$border-radius-base: 4px;


//-------------------------------------//
// COLORS

// colors: base font
$color-font-base: #414142;
$color-font-heading: #414142;
$color-font-light: lighten(#414142, 50%);

// colors: brand
$color-brand-1: #6fa8dc;
$color-brand-2: #c42278;
$color-brand-3: #e69138;
$color-brand-4: #a1d418;
$color-brand-5: #674ea7;
$color-brand-6: #93c47d;

$green-dark: #444;
$green-light: #e0ffef;
$green-gray: #73948e;
$pink-red: #ef0759;
$gray1: #efefef;
$gray2: #d9d9d9;
$gray-for-back: #ecf1f1;
$color-error: #b22222;
$color-success: #92dd5a;
$color-asset-button: #6ad0aa;

// color: banner overlay tint
$color-banner-tint: rgba(0, 0, 0, 0.3);

// colors: base background
$color-body-bg: $gray1;

// colors: links
$color-link: $color-font-base;
$color-link-hover: darken($color-link, 15%);
$color-menu-link: #dedede;
$color-menu-link-hover: lighten($color-menu-link, 15%);

// color: form input border
$color-input-border: #e4e4e4;

// color: dividers/borders
$color-divider: #e4e4e4;

// color: modal background
$color-modal-bg: #f5f7f9;


//-------------------------------------//
// FONTS

// fonts: base styles
$font-family-1: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-family-2: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-size-base: 14px;

// fonts: weights
$font-light: 300;
$font-normal: 400;
$font-medium: 500;
$font-bold: 700;


//-------------------------------------//
// SPACING

$spacing: 20px;
$spacing-double: $spacing * 2;
$spacing-third: $spacing * 3;
$spacing-half: $spacing / 2;


//-------------------------------------//
// Z:INDEX

$zindex-header: 1000;
$zindex-dropdown: 1100;
$zindex-lib-select2: 1200;  // plugin: Select2
$zindex-notify: 1300;
$zindex-modal: 1400;
$zindex-overlay: 100;

//-------------------------------------//
// MEDIA QUERIES

$small-width: 530px;
$medium-width: 885px;
