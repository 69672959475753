
.city {
    padding: 0;

    .water-mark {
        width: 150px;
        height: 130px;
        position: absolute;
        top: 70px;
        right: 100px;
        background-image: url("/images/beta.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        opacity: 0.5;
    }

    .only-devices {
        display: none;
    }

    /* For iceing */

    .js-ding-page-actualDing {
        background: #fff;
    }

    .card {
        box-shadow: none !important;
    }

    &__facebook-banner {
        color: white;
        width: 100%;
        margin: $spacing-third 0;
        background-color: $pink-red;
        text-align: center;
        font-size: 16px;
        padding: 0px $spacing-half;
        display: flex;
        align-items: center;
        justify-content: center;

        a {
            color: white;
            display: inline-block;
            font-size: 30px;
            margin-right: 10px;
            margin-left: 10px;
            padding-bottom: 2px;

            &:last-child {
                margin-left: 5px;
            }
        }
    }

    &__meetup {
        margin-right: 10px;
        margin-left: 3px;
    }

    &__facebook-comments {
        margin-top: $spacing-third;
    }

    &__show-all {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }

    .titles {
        margin: 0 auto;

        h1 {
            text-align: center;
            font-size: 22px !important;
        }

        &__bottom {
            margin-bottom: 15px;
        }
    }

    .events, .team {
        &__header {
            position: relative;
            height: 50px;

            .btn {
                position: absolute;
                right: 0;
                top: 60px;
                padding: 0 $spacing-half;
                font-size: 18px;
                line-height: 45px;
                height: 45px;
                margin: 0;
            }

            h1 {
                font-size: 25px;
                font-weight: $font-bold;
                margin: 0;
                line-height: 45px;
            }

            .gray {
                color: gray;
            }
        }

        &__list {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: flex-start;
            padding-left: 0px !important;
        }

        &__titles {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: flex-start;
        }

        @include screen-sm() {
            .btn {
                position: relative;
                display: block;
                width: 200px;
                margin: 0 auto;
            }
        }

        @include screen-xs() {
            margin: 0;
            padding: 0;
        }
    }
}

.container-iceing {
    background-color: #fff;
    padding: 0;
    margin: 0;
    box-shadow: 1px 1px 20px 14px #ECECEC75;
    border-radius: 10px;
    border: 1px solid #d7e3ec;
    border-top: 0px;
}

.bigIcon-container {
    display: flex;
    text-align: center;
}

.iceingTop {
    border-radius: 30px 30px 12px 12px;
    background: #fff;
    box-shadow: 0px 20px 20px 11px #ebe7e77a;
}

.iceingMiddle {
    padding: 0px;
    margin: 0px;
}

.iceingContainer {
    padding: 0px;
    margin: 0px;
}

.iceingTop2 {
    border-left: 1px solid #b0b0b0;
    border-right: 1px solid #b0b0b0;
    border-top: 1px solid #b0b0b0;
    border-radius: 30px 30px 0px 0px;
    background: #fff;
}

.iceingMiddle2 {
    border-left: 1px solid #b0b0b0;
    border-right: 1px solid #b0b0b0;
    padding: 0px;
    margin: 0px;
    background: #fff;
}

.toLeft {
    text-align: left !important;
}

.toRight {
    text-align: right !important;
}

.father-filter {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

.all-ding {
    width: 44px;
    vertical-align: bottom;
}

.ding-text-area {
    margin-top: 5px;
    background-image: none !important;
}


.ding-send {
    width: 56px;
    height: 56px;
    border-radius: 100%;
    background-color: #ef0759;
    margin-right: 13px;
    margin-bottom: -7px;
    cursor: pointer;

    &__1 {
        width: 52px;
        height: 51px;
        background-color: white;
        border-radius: 100%;
        position: absolute;
        margin-left: 2px;
        margin-top: 2px;
    }

    &__2 {
        width: 45px;
        height: 45px;
        background-color: #ef0759;
        border-radius: 100%;
        position: absolute;
        margin-left: 5px;
        margin-top: 5px;
    }

    &__3 {
        width: 40px;
        height: 40px;
        background-color: white;
        border-radius: 100%;
        position: absolute;
        margin-left: 7px;
        margin-top: 7px;
    }

    &__4 {
        width: 34px;
        height: 34px;
        background-color: #ef0759;
        border-radius: 100%;
        position: absolute;
        margin-left: 10px;
        margin-top: 10px;
    }

    &__5 {
        width: 29px;
        height: 29px;
        background-color: white;
        border-radius: 100%;
        position: absolute;
        margin-left: 12px;
        margin-top: 12px;
    }
}

.ding-search {
    width: 100%;
    border: 1px solid #e8e6e6;
    border-radius: 14px;
    padding: 0px 0px 4px 15px;
    display: inline-flex;

}

.ding-search input {
    width: 78%;
    margin: 0px;
    padding: 0px;
    height: 25px;
}

.ding-search input::placeholder {
    color: #e8e6e6;
    margin-left: 20px;
}

.ding-search > span {
    border: none;
    background-color: white;
    padding-right: 30px;
}


.team {
    font-size: 24px !important;
    color: #ef0759;
    margin-top: 6px;
}

.sprite-ding {
    cursor: pointer;
    margin: 0px 8px;
    overflow: hidden;
    display: inline-block;
}

.dings h1 {
    text-align: center; 
    font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    color: #414142;
    width: 100%;
    text-align: center;
    font-weight: 700;
    font-size: 22px !important;
    margin-top: 20px;
}

.ding-style {
    background-color: #fff;
    min-height: 110px;
    max-height: 111px;
    border-radius: 15px;
    border: 1px solid #d7e3ec;
    box-shadow: 1px 0px 20px 2px #f1f1f1;
    margin: 14px auto;
    text-align: left;
    box-sizing: border-box;
    transition: border 300ms ease-in-out;
    transition: box-shadow 300ms ease-in-out;

    padding-left: 0px;
    padding-right: 0px;

    h4 {
        font-size: 1.2rem;
    }
}



.ding-style:hover {
    border: 1px solid #ddd;
    box-shadow: 2px 2px 6px #a4a1a1a3;
}


.ding-style2 {
    background-color: #fff;
    min-height: 100px;
    margin: 14px auto;
    text-align: left;
    box-sizing: border-box;

    padding-left: 0px;
    padding-right: 0px;

    h4 {
        font-size: 1rem;
    }
}

.open-subjects-remodal {
    
    h4 {
        font-family: Source sans pro;
    }

    .subjects-for-talk {
        font-family: Source sans pro;
    }

    .subjects-for-talk {
        display: flex;
        width: 100%;
        margin: 15px 2px 10px 8px;
    }

    .matching-subjects {
        width: 50%;
    }
    .none-matching-subjects {
        width: 50%;
    }

}


.show-subjects-remodal {
    background: #fff;
    color: #414142;
    text-align: left;
    contain: content;
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
    width: 300px;
    border-radius: 25px;
    position: absolute;
    left: -60%;
    padding: 9px 20px 6px 9px;

    h4 {
        font-family: Source sans pro;
    }

    .subjects-for-talk {
        font-family: Source sans pro;
    }

    .subjects-for-talk {
        display: flex;
        width: 100%;
        margin: 15px 2px 10px 8px;
    }

    .matching-subjects {
        width: 50%;
    }
    .none-matching-subjects {
        width: 50%;
    }
}

.show-subjects-remodal2 {
    background: #fff;
    color: #414142;
    text-align: left;
    contain: content;
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
    width: 300px;
    border-radius: 25px;
    position: absolute;
    left: -230px;
    padding: 9px 20px 6px 9px;

    h4 {
        font-family: Source sans pro;
    }

    .subjects-for-talk {
        font-family: Source sans pro;
    }

    .subjects-for-talk {
        display: flex;
        width: 100%;
        margin: 15px 2px 10px 8px;
    }

    .matching-subjects {
        width: 50%;
    }
    .none-matching-subjects {
        width: 50%;
    }
}

.talk-comments {
    display: flex;
    top: 4px;
    right: 22px;
    position: absolute;
}

.ding-bar {
    .media-only-width {
        width: 120px;
        padding-right: 0px;
    }
}


.ding-bar2 {
    max-width: 500px;
    height: 164px;
    border-radius: 28px;
    box-shadow: 2px 2px 6px #dcdbdba3;
    padding: 0px 5px;
    margin: 20px auto;
    text-align: center;
    contain: content;
    box-sizing: border-box;


    .upload-button {
        color: #909090;
        width: 100%;
        height: 96%;
        border-radius: 28px;
        background-color: #f7f7f7;
        font-size: 80px;
        font-weight: lighter;
        text-align: center;
        cursor: pointer;
    }
}

.solution-title {
    font-size: 14px;
    padding: 0 !important;
    text-align: left;
    font-weight: bold;
    margin: 0 !important;
}

.only-for-solutions {
    display: flex;
    align-items: first baseline;

    span {
        font-size: 13px;
        padding-right: 5px;
    }
}

.image-mobile {
    display: none;
}

.elements {
    display: inline-flex;
    height: 100%;
    padding-top: 5px;
}

.ding-inputs {
    width: 56%;
    height: 100%;
    text-align: center;
}

#exchange-form {
    height: 100%;
}

#js-rp-bio {
    height: 75px;
}


.fishing-right {
    width: 17%;
}

.exchange-upload-img {
    vertical-align: middle;

    svg {
        width: 40px;
        height: 100%;
        padding-bottom: 4px;
    }
}

.fishing-net2 {
    cursor: pointer;
    background-color: #eee;
    width: 108px;
    height: 108px;
    position: absolute;
    top: 19%;
    right: -5%;
    border-radius: 65px 65px;
    z-index: -1;
}

.fishing-net3 {
    cursor: pointer;
    background-color: #eee;
    width: 67px;
    height: 68px;
    position: absolute;
    right: 0px;
    bottom: 24px;
    border-radius: 65px 0px 0px 65px;
    z-index: 1;
}

.ding-send2 {
    width: 56px;
    height: 56px;
    border-radius: 100%;
    background-color: #ef0759;
    margin-right: 3px;
    cursor: pointer;
    position: absolute;
    bottom: 51px;
    right: 5px;

    &__1 {
        width: 52px;
        height: 51px;
        background-color: white;
        border-radius: 100%;
        position: absolute;
        margin-left: 2px;
        margin-top: 2px;
    }

    &__2 {
        width: 45px;
        height: 45px;
        background-color: #ef0759;
        border-radius: 100%;
        position: absolute;
        margin-left: 5px;
        margin-top: 5px;
    }

    &__3 {
        width: 40px;
        height: 40px;
        background-color: white;
        border-radius: 100%;
        position: absolute;
        margin-left: 7px;
        margin-top: 7px;
    }

    &__4 {
        width: 34px;
        height: 34px;
        background-color: #ef0759;
        border-radius: 100%;
        position: absolute;
        margin-left: 10px;
        margin-top: 10px;
    }

    &__5 {
        width: 29px;
        height: 29px;
        background-color: white;
        border-radius: 100%;
        position: absolute;
        margin-left: 12px;
        margin-top: 12px;
    }
}

.flex {
    display: block;
}

.father-subject {
    text-align: center;
    display: table-caption;
}

.father-icons {
    width: 100%;
    text-align: center;
    display: flex;
    background: #fff;
    border-radius: 20px 20px 0px 0px;
}

.ding-description-bar {
    display: inline-block;
    width: 100%;
    height: 34px;
    background: linear-gradient(90deg, #47b8ff 20%, #52d9ef);
    border-radius: 10px;
    text-align: center;
}

.ding-description-bar span {
    font-size: 23px;
    font-weight: bold;
    color: #fff;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
}

.suggested {
    width: 55px;
    height: 20px;
    padding: 3px 3px 1px 6px;
    margin: 3px 12px 10px 0px;
    contain: layout;
    font-size: 0.8rem;
    box-sizing: border-box;
    display: table;
    text-align: center;
}

.ding-help {
    color: #b1a8a8;
    font-size: 14px;
    display: inline-block;
    width: 100%;
    text-align: center;
}

.ding-help-icon {
    font-size: 20px;
    color: #f3cf6a;
    margin-right: 20px;
}

.js-msg-dinghelp {
    display: flex;
}

.some-rows {
    text-align: center;
    float: right;
}

/* For new icons */

.icons-city {
    display: inline-flex;
    cursor: pointer;
    padding: 4px;
    margin: auto;
    background-size: 100% 200%;
    border-bottom-style: solid;
    transition: 0.2s;
    border-bottom-color: white;
    background-image: linear-gradient(to bottom, #fff 50%, #52d9ef 50%);
    border-radius: 45%;
    margin-top: 7px;
    margin-bottom: 2px;
}

.icons-city:hover {
    color: #fff;
    border-bottom-color: darken(#52d9ef, 20%);
    background-position: 0 100%;

    svg path.talk1 {
        stroke:#fff !important;
        fill: #fff !important;
    }

    svg path.a {
        stroke:#fff !important;
    }

    svg path.b {
        stroke:#fff !important;
        fill: #fff !important;
    }

    svg circle.a {
        stroke:#fff !important;
        fill: #fff !important;
    }

    svg path.browse {
        stroke: #fff !important;
    }
    svg circle.browse {
        stroke: #fff !important;
    }
    svg path.browse2 {
        fill: #fff !important;
    }

    svg rect.browse {
        stroke: #fff !important;
    }

    svg path.eyes {
        fill: #fff !important;
    }

}

.active-icon {
    color: #fff;
    border-bottom-color: darken(#52d9ef, 20%);
    background-position: 0 100%;

    svg path.a {
        stroke:#fff !important;
    }

    svg path.b {
        stroke:#fff !important;
        fill: #fff !important;
    }

    svg circle.a {
        stroke:#fff !important;
        fill: #fff !important;
    }

    svg path.talk1 {
        stroke:#fff !important;
        fill: #fff !important;
    }

    svg path.browse {
        stroke:#fff !important;
    }
    svg path.browse2 {
        fill: #fff !important;
    }

    svg rect.browse {
        stroke:#fff !important;
    }

    svg circle.browse {
        stroke: #fff !important;
    }

    svg path.eyes {
        fill: #fff !important;
    }

}

.icons {
    display: flex;
    bottom: 1px;
    position: absolute;
    height: 17px;
}

.mini-icon-text {
    padding-top: 0px;
    margin-right: 4px;
    margin-left: 3px;
    font-size: 0.75rem;
    height: 17px;
    color: #6b6b6b;
}

.js-qnt-comments {
    color: #b9b6b6;
}

.mini-icon-text-dingpage {
    margin-right: 8px;
    margin-left: 2px;
    font-size: 14px;
    height: 20px;
}

.textarea {
    white-space: normal
}

.deleting-box {
    width: 100%;
}

.delete-ding {
    top: 0;
    position: absolute;
    right: 24px;
    width: 32px;
    height: 16px;
    border-radius: 8px 8px 12px 12px;
    background-color: #f06f;
    cursor: pointer;
    z-index: 20;
}

.media-body {
    height: 110px;
    /* width: auto; */
    width: 100%;
    cursor: pointer;
    margin-right: 20px;
    padding-top: 7px !important;
    padding-right: 11px;
    padding-bottom: 3px;
    max-height: 110px;
    display: block;
    /* aquii */

    p {
        text-align: left;
        font-size: 0.85rem;
        font-family: Be Vietnam Pro;
        margin: 0px 36px 0px 1px;
        white-space: initial;
        max-height: 110px;
        color: #626262;
        word-break: break-word;
    }

    .media-text-for-main {
        font-size: 16px !important;
        margin: 3px 56px 0px 50px;
        font-family: Source sans pro;
    }

    h4 {
        line-height: 1.2rem;
    }
}

.media-body-eyes {
    cursor: default !important;
}

.fishing-net {
    cursor: pointer;
    background-color: #eee;
    width: 66px;
    height: 68px;
    position: absolute;
    right: 0px;
    bottom: 19px;
    border-radius: 65px 0px 0px 65px;
    z-index: -1;
}

.media-right {
    height: 100%;
    width: 67px;
    contain: content;
    box-sizing: border-box;
    padding-left: 10px;
    z-index: 2;
    vertical-align: bottom;
    position: absolute;
    right: 0;
    top: 0;
}

.button-send {
    height: 100%;
    width: 120px;
    contain: content;
    box-sizing: border-box;
    margin-left: 13px;
    padding-left: 27px;
    /* padding-right: 16px !important; */
    z-index: 2;
}

.media-heading {
    font-weight: bolder !important;
    padding-right: 52px;
}

.ding-image {
    width: 70px !important;
    height: 70px;
    border-radius: 100%;
    margin-right: 10px;
}

.dingLike_city {
    width: 45px;
    height: 45px;
    border-radius: 100%;
    border: 5px solid #ff609f;
    position: absolute;
    bottom: 35px;
    z-index: 3;
}

.botton-dingLike {
    bottom: 30px !important;
}

.liked {
    background-color: #f06f;
    cursor: pointer;
    border: 5px solid #ff609f;

    p {
        color: white;
        text-align: center;
        font-size: 18px;
        font-weight: bolder;
        width: 100%;
        height: 100%;
        display: flex;
        margin: 0 auto;
        justify-content: center;
        align-items: center;
    }
}

.notliked {
    background-color: white;
    cursor: pointer;

    p {
        color: white;
        text-align: center;
        margin-bottom: 2px;
        font-size: 15px;
    }
}


/*  STYLES FOR EXCHANGE BOX */

.exchange {
    width: 285px;
    min-height: 335px;
    position: relative;
    margin: 17px 7px 0px 7px;
    overflow: hidden;
    border-radius: 0px 0px 12px 12px;
    border: 1px solid #e8e8e8;
    box-shadow: 2px 2px 2px #dcdbdba3;
    padding-bottom: 10px;
    cursor: pointer;

    &__image {
        width: 100%;
        height: 170px;
        background: #fff;
        transition: 0.5s;
        overflow: hidden;

        img {
            width: 100%;
            max-height: 170px;
            object-fit: cover;
            transition: 0.5s;
            opacity: 0.85;
        }
    }

    &__actions {
        margin-top: $spacing-half;
        padding: $spacing-half;
        border-top: 2px solid $gray2;
        display: flex;
        justify-content: space-between;

        a {
            color: $color-brand-1;
            padding: 5px;
        }
    }

    &__controls {
        width: 100%;
        position: absolute;
        top: 0;
        background-color: rgba(50, 50, 50, 0.75);
        text-align: right;

        a {
            display: inline-block;
            padding: 2px;
            margin: 0 5px;
            color: #fff;
            font-size: 20px;
            transition: color 0.25s;
        }

        a:hover {
            color: $gray2;
        }
    }

    &__container {
        width: 100%;
        display: flex;

        .container-text {
            width: 100%;
            padding-right: 10px;
        }

        .container-likes {
            width: 22%;
            padding-top: 14px;
        }

        .container-shadow {
            position: absolute;
            width: 55px;
            height: 58px;
            border-radius: 30px 0px 0px 30px;
            background-color: #f3f2f2;
            top: 6%;
            z-index: 1;
            right: 0px;
        }
    }

    &__container2 {
        width: 100%;
        height: 22px;
        display: flex;
        margin: 2px 0px 0px 5px;
        position: unset;
    }

    .description {
        font-size: 13px;
    }

    .for-your {
        font-size: 12px;
        color: #ea2883;
        font-weight: bolder;
    }


    .dingLike_exchange {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        border: 4px solid #ff609f;
        display: flex;
        justify-content: center;
        align-content: center;
        flex-direction: column;
        cursor: pointer;
        z-index: 2;
    }

    h3, h4 {
        font-size: 16px;
        text-align: left;
        margin: 0;
        padding: 0;
        margin-left: 7px;
    }

    h3 {
        margin: 10px 4px 5px 0;
        font-weight: $font-bold;
        margin-left: 7px;
    }

    h4 {
        margin-bottom: $spacing-half;
        margin-left: 7px;
    }

    p {
        margin-left: 7px;
        padding: 0px 3px 0px 0px;
        text-align: left;
        margin-bottom: 2px;
    }

    .js-likes-ppl {
        text-align: center;
        margin: 0;
    }

    &:hover {
        img {
            transform: rotate(5deg) scale(1.3, 1.3);
            opacity: 1;
        }

        .event__controls {
            transform: translateY(0);
        }
    }
}

.upload-img {
    cursor: pointer;
}

.type-exchange {

    width: 100%;
    display: flex;
    padding-left: 3px;

    .forYour-exchange {
        font-size: 12px !important;
        text-align: left;
        font-weight: bolder;
        display: inline;
        margin-right: 5px;
        color: #f06;
    }

    .js-exchange-for {
        font-size: 12px !important;
        text-align: left;
        display: inline;
        color: #f06;
        font-weight: 600;
    }
}

/* STYLES FOR CRYPTO DINGS */

.crypto-style {
    background-color: #fff;
    min-height: 110px;
    /* box-shadow: 2px 2px 6px #dcdbdba3; */
    margin: 14px auto;
    text-align: left;
    box-sizing: border-box;

    padding-left: 0px;
    padding-right: 0px;

    h4 {
        font-size: 1rem;
    }

    .media-body {
        box-shadow: 2px 3px 5px 2px #dcdbdba3;
    }

    .crypto-image {
        height: 120px;
        width: 120px;
        padding-right: 0px;
    }

    .crypto-media-body {
        padding: 9px 7px 0px 6px;

        h4 {
            font-size: 1rem;
        }
    }

    .crypto-media-txt {
        text-align: left;
    }

    .crypto-media-vote {
        display: none;
    }
}

/* STYLES FOR KEYBAY AT DING PAGE */


.no-lines {
    border: none;
    width: auto;
}

.key-bar-spacing {
    margin-bottom: 7px;
}

.only-mobile {
    display: none;
}

.fixing-plus-red {
    display: table-cell;
    position: absolute;
    left: -25px;
    top: 4px;
}

.ding-search2 {
    border: 1px solid #e8e6e6;
    border-radius: 30px;
    padding: 8px;
    display: block;
    margin: 20px;
    contain: content;
    background: #fff;
    font-family: roboto;

    .svg-editing {
        width: 33px !important;
        height: 31px !important;
        top: 3px;
        left: 10px;
    }

    .input-keys {
        border: none;
        font-size: 1.3rem;
        font-weight: 400;
        font-family: 'Source sans pro';
        color: #323232;
    }

    .input-keys::placeholder {
        font-size: 1.3rem;
        font-weight: 400;
        font-family: 'Source sans pro';
        color: #848484;
    }

    .keybar-logo {
        width: 55px;
        height: 55px;
        margin-right: 10px;
    }

    .keys-container {
        width: 95%;
        display: flex;
    }

    .keys-container svg {
        padding-bottom: 6px;
        padding-top: 4px;
        height: 32px;
        opacity: 65%; 
    }

    .hat-container {
        width: 56px;
        position: absolute;
        right: 0px;
        top: -7px;
    }


    .talk-dings {
        margin-left: 10px;
        width: 85%;
        height: auto;
        resize: none;
        word-break: break-word;
        margin-top: 4px;
        font-size: 1.4rem;
        font-family: Source Sans Pro;
    }

    .exchange-dings {
        display: flex;
        width: 85%;
        font-weight: 300;

        .new-image {
            display: none;
        }
        #exchange-form {
            width: 70%;
        }

        .upload-box-keybar {
            width: 100px;
        }

        input::placeholder {
            color: #e4e2e2;
        }
    }

    /* Action - keybar */

    .action-dings {
        display: flex;
        width: 85%;
        font-weight: 300;

        .new-image {
            display: none;
        }
        #action-form {
            width: 70%;
        }
        .action-title {
            border: 0px;
            border-bottom: 1px solid #cbcbcb;
            margin: 2px;
            resize: none;
            width: 90%;
            height: 25px !important;
            font-size: 1.2rem;
            font-weight: 600;
            font-family: "Roboto";
            color: #414142;
        }

        .action-title::placeholder {
            color: #9f9f9f;
            font-size: 1.2rem;
            font-weight: 400;
            font-family: 'Roboto';
        }

        .action-textarea {
            border: 0px;
            margin: 2px;
            resize: none;
            width: 90%;
            height: 65px;
        }

        .action-textarea::placeholder {
            color: #a3a3a3;
            font-size: 0.95rem;
            font-weight: 400;
            font-family: 'Roboto';
        }

        .action-link {
            border: 0px;
            margin: 2px;
            resize: none;
            width: 90%;
            height: 25px !important;
        }
        #js-action-link::placeholder {
            color: #47b8ff;
            font-weight: bold;
            font-size: 15px;
        }

        .upload-box-keybar {
            width: 100px;
        }
    }

    /* Exchange inputs keybar */

    .exchange-dings {
        .exchange-contact {
            display: flex;
            justify-content: center;
        }

        .exchange-icons {
            height: 42px;
        }

        .exchange-icons svg {
            height: 30px;
            width: 36px;
            cursor: pointer;
            margin-top: 5px;
        }

        .exchange-icons.active {
            border-bottom: 4px solid #d2bc42;
        }
    }

    .upload-button2 {
        color: #909090;
        border-radius: 28px;
        background-color: #f7f7f7;
        font-size: 70px;
        font-weight: lighter;
        text-align: center;
        cursor: pointer;
    }

    #js-offer {
        font-size: 1.2rem;
        font-weight: 600;
        font-family: "Roboto";
        color: #414142;
    }

    #exchange-form input {
        border: 0px;
        border-bottom: 1px solid #cbcbcb;
        margin: 2px;
        resize: none;
        width: 100%;
        height: 25px !important;
    }

    #exchange-form input::placeholder {
        color: #9f9f9f;
        font-size: 1.2rem;
        font-weight: 400;
        font-family: 'Roboto';
    }

    #exchange-form textarea {
        margin: 2px;
        resize: none;
        width: 100%;
        height: 65px;
        border: 0px;
    }

    #exchange-form textarea::placeholder {
        color: #a3a3a3;
        font-size: 0.95rem;
        font-weight: 400;
        font-family: 'Roboto';
    }

    #exchange-form .url-exchange::placeholder {
        color: #47b8ff;
        font-weight: bold;
        font-size: 15px;
    }

    .url-exchange {
        border-bottom: 0px !important;
    }

    /* Meet - keybar */

    .meet-dings {
        display: flex;
        width: 85%;
        min-height: 155px;
        max-height: 200px;
        font-weight: 300;

        .meet-contact {
            display: flex;
            justify-content: center;
        }

        .meet-icons {
            height: 42px;
        }

        .meet-icons svg {
            height: 30px;
            width: 36px;
            cursor: pointer;
            margin-top: 5px;
        }

        .meet-icons.active {
            border-bottom: 4px solid #d2bc42;
        }

        .new-image {
            display: none;
        }

        .upload-box-keybar {
            width: 100px;
        }

        #meet-form {
            width: 70%;
            margin-left: 5px;
        }

        /* font awesome icons */

        .js-frecuency::after {
            content: "\f0d7";
            font-family: FontAwesome;
            position: absolute;
            top: -6px;
            left: 3px;
            color: #595858;
            font-size: 25px;
        }

        .js-select-date::after {
            content: "\f073";
            font-family: FontAwesome;
            position: absolute;
            top: 0;
            right: 4px;
            color: #595858;
        }

        .js-select-time::after {
            content: "\f0d7";
            font-family: FontAwesome;
            position: absolute;
            top: -6px;
            right: 4px;
            color: #595858;
            font-size: 25px;
        }

        label.js-frecuency {
            padding: 0px 5px 0px 21px;
        }

        .d-input {
            max-height: 60px;
            width: 99%;
        }

        .meet-title {
            font-size: 1.2rem;
            font-weight: 600;
            font-family: "Roboto";
            color: #414142;
        }

        .meet-title, .url-meet {
            border: 0px;
            border-bottom: 1px solid #cbcbcb;
            border: 0px;
            margin: 0px;
            resize: none;
            width: 100%;
            height: 25px !important;
        }

        .meet-title::placeholder {
            color: #9f9f9f;
            font-size: 1.2rem;
            font-weight: 400;
            font-family: 'Roboto';
        }

        .url-meet::placeholder {
            color: #47b8ff;
            font-weight: bold;
            font-size: 15px;
        }

        .form-group {
            margin: 0 2px !important;
            padding: 0 !important;
        }

        .label-floating {
            border: 0px;
            border-bottom: 1px solid #cbcbcb;
        }

        .form-control {
            background-image: none !important;
            padding: 10px 10px 5px 10px;
            margin: 0;
            line-height: 16px;
            font-size: 14px;
            height: 29px;
            min-width: 95px;
        }

        .form-group.label-floating {
            border-radius: 0px !important;
        }

        .form-group.label-floating label.control-label, .form-group.label-placeholder label.control-label {
            top: -12px;
        }
        .form-group.label-floating:not(.is-empty) label.control-label {
            top: -15px !important;
        }

        .no-borders {
            width: 90%;
            border: 0px;
            margin: 2px;
            resize: none;
            max-height: 55px;
        }

        .no-borders::placeholder {
            color: #a3a3a3;
            font-size: 0.95rem;
            font-weight: 400;
            font-family: 'Roboto';
        }

    }

    /* Browse - keybar */

    .browse-dings {
        display: flex;
        width: 85%;
        font-weight: 300;

        .new-image {
            display: none;
        }

        .upload-box-keybar {
            width: 100px;
        }

        #browse-form {
            width: 70%;
        }

        #js-browse-link {
            font-size: 1.2rem;
            font-weight: 600;
            font-family: "Roboto";
            color: #414142;
        }

        #js-browse-link::placeholder {
            color: #47b8ff;
            font-weight: bold;
            font-size: 15px;
            font-family: 'Roboto';
        }

        #js-browse-title-input::placeholder {
            color: #a3a3a3;
            font-size: 0.95rem;
            font-weight: 400;
            font-size: 15px;
            font-family: 'Roboto';
        }
    }

    #browse-form input {
        border: 0px;
        border-bottom: 1px solid #cbcbcb;
        margin: 2px;
        resize: none;
        width: 100%;
        height: 25px !important;
    }

    #js-browse-link {
        border-bottom: 0px !important;
    }

    #browse-form textarea {
        border: 0px;
        margin: 2px;
        resize: none;
        width: 100%;
        height: 65px;
    }

    #browse-form textarea::placeholder {
        color: #a3a3a3;
        font-size: 0.95rem;
        font-weight: 400;
        font-family: 'Roboto';
    }

    /* RP - keybar */

    .rp-dings {
        display: flex;
        max-height: 140px;
        font-weight: 300;

        .rp-inputs {
            width: 55%;
            text-align: left;
        }

        .upload-box {
            width: 25%;
            font-size: 20px;
            height: 100%;
            display: inline;
            max-width: 100px;
        }

        .upload-button {
            color: #909090;
            width: 100px;
            height: 52px;
            border-radius: 28px;
            background-color: white;
            font-size: 35px;
            font-weight: lighter;
            text-align: center;
            cursor: pointer;
            margin-top: 9px;
        }

        .select-type-rp {
            .rp-help {
                width: 100%;
                display: flex;
                height: 25px;
            }

            .rp-help i {
                font-size: 20px;
                color: #909090;
                cursor: pointer;
                margin: 0 auto;
            }
        }

        .location {
            display: inline-flex;
            font-size: 14px;
            padding: 1px 4px 4px 2px;
            color: #909090;
            cursor: pointer;
            height: 30px;

            i {
                font-size: 20px;
                color: #909090;
                margin-right: 15px;
            }

            p {
                margin: 3px 0 3px;
            }
        }

        .roaming {
            display: inline-flex;
            font-size: 14px;
            padding: 1px 4px 4px 2px;
            color: #909090;
            cursor: pointer;
            height: 30px;

            i {
                font-size: 20px;
                color: #909090;
                margin-right: 11px;
            }

            p {
                margin: 3px 0 3px;
            }

        }

        .new-image {
            width: 0px;
            height: 0px;
        }

        #profile-form {
            input {
                height: 25px;
                border: 0px;
                border-bottom: 1px solid #cbcbcb;
                width: 100%;
            }
            textarea {
                width: 100%;
                resize: none;
                height: 65px;
                border: 0px;
            }
        }

        #js-rp-title {
            font-size: 1.2rem;
            font-weight: 600;
            font-family: "Roboto";
            color: #414142;
        }

        #js-rp-title::placeholder {
            color: #9f9f9f;
            font-size: 1.2rem;
            font-weight: 400;
            font-family: 'Roboto';
        }

        #js-rp-website {
            border-bottom: 0px !important;
        }

        #js-rp-website::placeholder {
            color: #47b8ff;
            font-weight: bold;
            font-size: 15px;
        }

        #js-rp-bio::placeholder {
            color: #a3a3a3;
            font-size: 0.95rem;
            font-weight: 400;
            font-family: 'Roboto';
        }
    }

    .eyes-dings {
        display: flex;
        width: 85%;
        font-weight: 300;

        .new-image {
            display: none;
        }

        .upload-box-keybar {
            width: 100px;
        }

        #eyes-form {
            width: 100%;
        }

        .eyes-inputs {
            width: 70%;
            height: 100%;
        }

        #eyes-form input {
            margin: 2px;
            resize: none;
            width: 100%;
            height: 25px !important;
        }
        #eyes-form textarea {
            margin: 2px;
            resize: none;
            width: 100%;
            height: 65px;
        }

        #js-eyes-angel,#js-eyes-evidence-type {
            border: none;
            border-bottom: 1px solid #cbcbcb !important;
            background: #fff;
            font-size: 20px;
            font-weight: 300;
            font-family: "Roboto";
            color: #414142;
            width: 100%;
        }
        #js-eyes-angel::placeholder,#js-eyes-evidence-type::placeholder {
            color: #a3a3a3;
            font-size: 0.95rem;
            font-weight: 400;
            font-size: 15px;
            font-family: 'Roboto';
        }

        #js-eyes-evidence, #js-eyes-description-input {
            border: none;
            font-size: 20px;
            font-weight: 300;
            font-family: "Roboto";
            color: #414142;
        }

        #js-eyes-description-input::placeholder {
            color: #a3a3a3;
            font-size: 0.95rem;
            font-weight: 400;
            font-size: 15px;
            font-family: 'Roboto';
        }
        #js-eyes-evidence::placeholder {
            color: #47b8ff;
            font-size: 0.95rem;
            font-weight: 700;
            font-size: 15px;
            font-family: 'Roboto';
        }
    }

    .container-send-div {
        width: 100%;
        flex-direction: row;
        display: flex;
        justify-content: flex-end;
        height: 5px;

        .send-div {
            width: 40px;
            height: 30px;
            position: absolute;
            bottom: 4px;
            cursor: pointer;
            right: 0px;
        }
        .ding-send_2 {
            width: 45px;
            height: 45px;
            background-color: #fff;
            border: 7px solid #ef0759;
            border-radius: 100%;
            position: absolute;
            top: -13px;
            left: -7px;
            z-index: 5;
        }
        .fishing-net-keybar {
            cursor: pointer;
            background-color: #eee;
            width: 64px;
            height: 60px;
            position: absolute;
            right: -9px;
            border-radius: 30px 0px 24px 30px;
            z-index: 1;
            bottom: -6px;
        }
    }

    .crypto-dings {

        width: 80%;
        display: grid;
        padding-top: 14px;
        padding-left: 10px;
        font-weight: 300;

        .cryptobox-inputs {
            display: flex;
        }

        .crypto-inp {
            max-width: 54px;
            max-height: 25px;
            margin: 2px;
            border: none;
            border-bottom: 1px solid #cbcbcb !important;
            font-size: 20px;
            font-weight: 300;
            font-family: "Roboto";
            color: #414142;
        }

        .crypto-inp2 {
            max-width: 54px;
            max-height: 25px;
            margin: 2px;
            border: none;
            border-bottom: 1px solid #cbcbcb !important;
            font-size: 20px;
            font-weight: 300;
            font-family: "Roboto";
            color: #414142;
        }

        .crypto-descrp {
            resize: none;
            width: 327px;
            margin-bottom: 4px;
            border: none;
            border-bottom: 1px solid #cbcbcb !important;
            font-size: 20px;
            font-weight: 300;
            font-family: "Roboto";
            color: #414142;
        }

        .crypto-inp::placeholder, .crypto-inp2::placeholder, .crypto-descrp::placeholder {
            color: #a3a3a3;
            font-size: 0.95rem;
            font-weight: 400;
            font-size: 15px;
            font-family: 'Roboto';
        }

        #js-crypto-time {
            max-width: 110px;
            border: none;
            border-bottom: 1px solid #cbcbcb !important;
            font-size: 20px;
            font-weight: 300;
            font-family: "Roboto";
            color: #414142;
            background: #fff;
        }

    }


    .select-typeDing {
        width: 60px;
    }

    .big-container {
        width: 100%;
        height: 22px;
        display: flex;
        margin-top: 12px;

        .container-select-ding {
            width: 14%;
            display: flex;
        }

        .container-select-ding2 {
            width: 40px;
            display: flex;
        }

        .badges-container {
            width: 80%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

    }

    .container-inputs-keybar {
        width: 100%;
        display: flex;
    }


    .ding-search2 img {
        width: 50px;
        height: 50px;
        margin: 4px 13px 0px 5px;
    }

    .a {
        display: inline-block;
    }
    .badges {
        display: inline-flex;   
        align-items: center;
        justify-content: center; 
    }

    .result {
        height: 27px;
        min-width: 65px;
        border-radius: 13px;
        border: 3px solid #e91e63;
        box-shadow: 1px 2px 2px 2px #efeeee;
        padding: 0px;
        margin: 0px 2px;
        background-color: #fff;
        color: #f06f;
        box-sizing: border-box;
        text-align: center;
        cursor: pointer;
        font-weight: bolder;

        .result-input {
            font-size: 1.05rem;
            margin: 0px 5px 0px 5px;
            height: 100%;
        }

        .result-delete {
            height: 0%;
            overflow: hidden;
            color: #f06f;
            font-weight: bolder;
            transition: opacity 0.5s ease-out;
            opacity: 0;

            p {
                line-height: 15px;
            }
        }
    }

    .result:hover {

        .result-delete {
            height: 115%;
            opacity: 1;
            display: block;
            padding-top: 3px;
            margin-bottom: 20px;
        }
    }

    .result {
        transition: display 0.8s linear 0.2s;
    }

    .ding-search2 input {
        width: 80%;
        height: 50px;
    }

    .ding-search2 input::placeholder {
        margin-left: 20px;
        font-weight: bolder;
        font-size: 15px;
    }

    .add-keys {
        min-width: 63px !important;
        height: 20px;
        border-radius: 5px;
        box-shadow: 1px 2px 2px 2px #efeeee;
        padding: 3px 4px 5px 15px;
        margin: -1px 0px 3px 23px;
        contain: layout;
        box-sizing: border-box;
        text-align: left;
        cursor: pointer;

        .add-more-keys {
            font-size: 11px;
            margin-right: 5px;
            margin-left: 5px;
        }

        .button-add-keys {
            position: absolute;
            top: -37%;
            left: -11px;
            width: 35px;
            height: 35px;
            border-radius: 100%;
            padding: 5px 1px 4px 1px;
            font-size: 11px;
            font-size: 0.78571rem;
            color: white;
            text-align: center;
        }

        .div-button {
            width: 96%;
            height: 100%;
            display: flex;

            .hat-image {
                margin: 0px 0px 0px 0px !important;
                width: 32px !important;
                height: 32px;
                object-fit: cover;
                border-radius: 100%;
                transition: box-shadow 0.5s;
                position: absolute;
                top: -5px;
                left: -5px;
                max-width: initial;
            }
        }

        svg.plus-button {
            position: initial;
            width: 26px !important;
            height: 100% !important;
            padding-left: 1px;
        }

    }

    .remove-width {
        min-width: 0px !important;
        border-radius: 15px;
        padding: 0px 0px 0px 0px;
        margin: 0px 0px 0px 22px;
    }

    .person6 {
        margin: 3px 5px;
        padding: 0px !important;
        display: flex;
        cursor: pointer;
        width: fit-content;
        width: 52px;
        height: 52px;
    }
    .person6_image {
        margin: 0px 0px 0px 0px !important;
        object-fit: cover;
        border-radius: 100%;
        transition: box-shadow 0.5s;
        height: 50px;
        width: 50px;
    }

}




/* STYLES FOR EXCHANGE BOX AT DING-PAGE */


.meet-button {
    display: flex;
    font-size: 13px;
    background-color: #40acff;
    border-radius: 7px;
    max-height: 26px;
    padding: 3px 6px 3px 2px;
    color: white;
    cursor: pointer;
    font-family: 'Manjari', sans-serif;
}

.meet-button p {
    margin: 2px;
}


.container-exchange {
    min-height: 500px;
    margin: 0 auto;
    box-shadow: 2px 2px 6px #dcdbdba3;
    border-radius: 0px 0px 26px 26px;
    padding-bottom: 15px;
}

.container-exchange2 {
    min-height: 500px;
    padding-bottom: 5px;
    margin-bottom: 30px;
    background: #fff;
    border-radius: 30px 30px 12px 12px;

    .exchange-for {
        font-weight: bolder;
        font-size: 15px;
        display: flex;
        margin-left: 10px;
    }

    .total {
        font-weight: bolder;
        font-size: 15px;
        color: #ea2883;
        margin: 0px 0px 0px 10px;
    }

    .no-solutions {
        margin-left: 4px;
        font-weight: bolder;
        font-size: 15px;
        color: #57b2b7;
    }

    .satoshi-amount {
        font-size: 15px;
        display: flex;
        margin-left: 20px;
    }
    .personTalk {
        margin: 10px 5px 5px 5px !important;
        padding: 0px !important;
        width: 35px;
        position: absolute;
        z-index: 99;
    }
}

.js-meet-container2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}


/* STYLES FOR ACTIONS AND HINT BAR-REMODAL */

.container-hints {
    display: inline-flex;
    vertical-align: middle;
    justify-content: center;
    margin: 5px auto;
    width: 100%;
    background: #fff;
    border-radius: 11px;
    padding: 15px;
}

.no-solution-hint {
    border: 1px solid #cac7c7;
    display: inline-flex;
    width: 200px;
    margin: auto;
    border-radius: 20px;
    cursor: pointer;
    background: #fff;

    .part1 {
        width: 74%;
        font-size: 13px;
        padding-left: 16px;

        h4 {
            margin-bottom: 4px;
        }
    }

    .part1 p {
        font-size: 11px;
    }

    .part2 svg {
        display: inline;
        vertical-align: middle;
        height: 94%;
    }
}

.actions-remind {
    border: 1px solid #47b8ff;
    width: 330px;
    margin: auto;
    margin-top: 25px;
    border-radius: 20px;
    padding: 20px;
    font-size: 14px;
    background: #fff;

    h2 {
        font-size: 25px;
        font-weight: 500;
        margin: 0px 0px 11px 0px;
    }
    h4 {
        font-size: 16px;
        font-weight: 500;
        margin-top: 14px;
        margin-bottom: 14px;
    }

    .flex-text-icon {
        display: flex;
        margin-bottom: 10px;
    }

    .text-part {
        width: 80%;
    }

    .icon-part {
        width: 22%;
    }

    .icon-part svg {
        display: inline;
        vertical-align: middle;
        height: 52%;
        width: 100%;
    }

    .flex-text-icon > .text-part p {
        margin: 0;
    }
}

.solution-found {
    border: 2px solid #1bbfff;
    border-radius: 15px;
    padding: 10px 5px 25px 5px;
    background: #fff;
    margin: 10px 0px;

    h3 {
        text-align: center;
        color: #1986b0;
        font-size: 20px;
        font-weight: bold;
    }
}

.incoming-solution {
    max-width: 340px;
    min-width: 330px;
    border-radius: 15px !important;
    padding: 10px 10px;
    margin: 0 auto;

    h2 {
        font-size: 22px;
        margin: 4px 0px;
    }
    h3 {
        font-size: 15px;
        margin: 4px 0px;
    }

    p {
        font-size: 14px !important;
        font-weight: 400;
    }

    .width-text {
        width: 77%;
    }
}

.poll-opened-remodal {
    max-width: 340px;
    min-width: 330px;
    border-radius: 15px !important;
    padding: 10px 10px;
    margin: 0 auto;

    h2 {
        font-size: 22px;
        margin: 4px 0px;
    }
    h3 {
        font-size: 15px;
        margin: 4px 0px;
    }

    p {
        font-size: 14px !important;
        font-weight: 400;
    }

    .width-text {
        width: 77%;
    }
}

.accept-harvesting-solution {
    max-width: 300px;
    min-width: 300px;
    border: 2px solid #1bbfff;
    border-radius: 15px;
    padding: 10px 10px;
    margin: 0 auto;

    .container-text-icon {
        display: flex;
    }

    .title {
        width: 70%;

        h4 {
            font-size: 15px;
            font-weight: bold;
        }
    }

    .svg-icon {
        width: 30%;

        svg {
            width: 100%;
            height: 60px;
        }
    }

    .text-for-harvesting {
        font-size: 14px;
    }

    .button-harvest {
        display: flex;
        max-width: 160px;
        margin: 0 auto;
        box-shadow: 1px 2px 2px 2px #e3e0e0;
        border-radius: 11px;
        cursor: pointer;
    }

    .button-text {
        width: 60%;

        p {
            font-size: 12px;
            text-align: center;
        }
    }
}

.accept-harvesting-remodal-btc {
    font-family: Source sans pro;
    max-width: 360px;
    min-width: 360px;
    padding: 20px 20px 20px 20px;
    text-align: left;
    color: #414142;
    contain: content;
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
    border-radius: 26px !important;

    .hint-title-remodal {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 12px;
    }

    .hint-title-remodal img {
        width: 20%;
    }

    .hint-title-remodal p {
        font-size: 20px;
        font-weight: 600;
        margin-right: 7px;
        width: 80%;
        margin-bottom: 0;
    }

    .hint-content-remodal {
        margin-bottom: 15px;
    }

    .hint-content-remodal p {
        font-size: 14px;
        font-weight: 600;
    }

    .hint-bolder p {
        font-size: 11px;
        font-weight: 600;
        color: #6f6f6f;
        width: 75%;
    }
    
    .input-btc {
        border: none;
        border-bottom: 1px solid #d5d5d5;
        margin-bottom: 15px;
        font-size: 15px;
        font-weight: 500;
        width: 100%;
        height: 35px;
    }

    .input-btc::placeholder {
        font-size: 16px;
        font-weight: bold;
        font-style: italic;
        color: #000;
    }

    .btn {
        font-weight: 600;
    }
    .btn-secondary {
        font-weight: 400;
    }
}

.open-image-eyes {
    max-width: max-content !important;
    max-height: max-content;

    h4 {
        font-weight: 600;
    }
    
    p {
        word-break: break-word;
        font-size: 14px;
    }
}

.solutions-bar {
    width: 100%;
    height: 40px;
    background-color: #67c5db;
    padding: 3px 0px;
    text-align: center;
    display: flex;

    .text-icon {

        width: 90%;

        h2 {
            font-weight: bold;
            color: #fff;
            padding: 0px;
            margin: 0;
            text-align: center;
        }

    }

    .hint-icon {
        cursor: pointer;
    }

    .hint-icon p {
        margin: 0px;
        vertical-align: middle;
        display: inline;
    }

    .hint-icon svg {
        width: 27px;
        height: 35px;
    }
}

.push-to-down {
    bottom: -117px !important;
}

.push-to-down2 {
    width: 65px !important;
    height: 63px !important;
    right: 0px;
    bottom: -126px !important;
}

.only-0-1-subjects {
    padding: 23px 25px 23px 25px;
    border-radius: 25px !important;
    max-width: 310px;
    min-width: 310px;
    font-family: Source sans pro;

    .rp-start {
        h4 {
            font-size: 18px;
            font-weight: 600;
        }
    }

    .subject-remodal-inside p {
        color: #888484;
    }

    .roleplay-remodal-inside p {
        font-size: 14px;
    }

}

.harvest-several-subjects {
    max-width: 280px;
    min-width: 280px;
    padding: 23px 25px 23px 25px;
    border-radius: 25px !important;
    font-family: Source sans pro;

    .person5 {
        margin-right: 0;
    }
    
    .person5 h4 {
        text-align: center;
        font-size: 15px;
        color: #8e8e8e;
    }

    .div-svg {
        width: 100%;
        height: 50px;
        text-align: center;
    }

    .div-svg svg {
        height: 100%;
    }

    .person5 img {
        width: 47px;
        height: 47px;
        margin-right: 6px;
    }

    .rp-text {
        padding: 0;
    }

    .rp-text h4 {
        font-weight: 400;
        text-align: center;
        color: #4d4d4f;
        font-size: 12px;
    }

    .rp-votes {
        width: 32px;
        height: 32px;
        margin-left: 5px;
        margin-top: 0;
    }
    .rp-votes p {
        font-size: 12px !important;
        color: #fff !important;
    }

    .rp-start {
        h4 {
            font-size: 18px;
            font-weight: 600;
        }
    }

    .subject-remodal-inside p {

        text-align: center;
        font-size: 15px;
        color: #888484;
    }

    .rp-style {
        width: 85%;
        padding: 5px;
        height: 50px;
    }

    .rp-style {
        .person5 img {
            width: 35px;
            height: 35px;
            margin-right: 0px;
        }
    }

    .final-keys-container {
        display: grid;
        text-align: center;
        justify-content: center;
        margin: 0 auto;
    }
}

.hint-remodal-inside {

    svg {
        position: relative;
        width: 60px;
        height: 60px;
    }

    .hint-title-remodal {
        display: flex;
    }

    .hint-title-big h4 {
        font-size: 17px;
        font-weight: bold;
    }

    .hint-title-remodal h4 {
        font-size: 14px;
        font-weight: bold;
    }

    .text-p-normal p {
        font-size: 14px;
    }

    p {
        font-size: 13px;
    }

    .hint-bolder {
        font-weight: bold;
    }

    .hint-bolder span {
        color: #3e97ac;
    }
}

.container-exchange-user {
    width: 100%;
    display: inline-flex;
    align-items: center;
    margin-top: 7px;
}

.container-exchange-paragraph {
    vertical-align: middle;
    margin: 0px 20px 0px 5px;
    display: inline-flex;
    width: 90%;
    font-size: 20px;
    font-family: Source sans pro;
    word-break: break-word;

    p {
        margin-left: 6px;
        text-align: left;
        font-size: 20px;
        font-family: source sans pro;
        word-break: break-word;
    }   
}

.container-exchange-text {
    vertical-align: middle;
    justify-content: flex-start;
    margin: 2px 20px 0px 4px;
    display: inline-flex;
    align-items: center;
    display: flex;

    p {
        margin-left: 6px;
    }

    .svg-icons-for-dingpage {
        height: 30px;
        margin-left: 7px;
    }

    svg {
        width: 30px;
        height: 30px;
    }
}


.container-exchange-text1 {
    justify-content: flex-start;
    margin: 2px 0px 0px 0px;
    display: flex;
    align-items: center;
    width: 90%;

    h1 {
        font-weight: bolder;
        font-size: 25px;
        padding: 0;
        margin: 3px 0px 0px 10px;
    }
}

.talk-comments2 {
    display: flex;
    top: 14px;
    right: 10px;
    position: absolute;
}

.talk-comments3 {
    display: flex;
    top: 5px;
    right: 10px;
    position: absolute;
    justify-content: center;
    align-content: center;
    align-items: center;

    svg.miniIcons {
        position: relative;
        width: 20px;
        height: 31px;
    }

    .mini-icon-text {
        padding-top: 0px;
        margin-right: 4px;
        margin-left: 3px;
        font-size: 11px;
        height: 17px;
        color: #6b6b6b;
    }
}

.container-exchange-text h3 {
    font-weight: bolder;
    font-size: 18px;
    padding: 0;
    margin: 0px 0px 0px 0px;
}

.container-exchange-imageText {
    width: 100%;
    display: block;
    margin-top: 5px;
    margin-bottom: 15px;
}

.ex-img {
    width: 100%;
    max-width: unset !important;
    object-fit: cover;
    cursor: pointer;
}

.ex-txt {
    margin: 10px 0px 0px 10px;
    width: 77%;
}

.container-exchange-icons {
    display: flex;
    width: 71%;
    text-align: left;
    min-height: 30px;
    margin-top: 5px;
}


.container-exchange-filters {
    display: grid;
    width: 100%;
    padding-left: 4px;
    text-align: left;

    .container-exchange-subjects {
        margin: 0px 0px 0px 7px;
        width: 115%;
        display: inline-block;
        height: auto;
    }

    .exch-icons {
        display: flex;
        align-items: flex-start;
    }

    .exch-icons svg {
        width: 32px;
        height: 18px;
    }

    .link-to-external {
        color: #f06;
        cursor: pointer;
        font-weight: bolder;
        font-size: 15px;
    }
}

#js-only-for-meets {
    width: 100%;

    .container-exchange-filters {
        display: flex;
        width: 100%;
        text-align: left;
        justify-content: center;
    }
}

.upper-icons {
    display: none;
}

.profile-subjects2 {
    display: flex;
    min-width: 0px !important;
    height: 13px;
    border-radius: 5px;
    box-shadow: 1px 2px 2px 2px #efeeee;
    padding: 0;
    margin: 3px 18px 15px 2px;
    background: white;
    contain: layout;
    box-sizing: border-box;
    text-align: left;
    cursor: pointer;
    float: right;

    .profile-languages2 {
        font-size: 11px;
        margin-right: 5px;
        margin-left: 3px;
        word-break: initial;
    }

    .ding-section-votes {
        top: 0%;
        width: 22px;
        height: 16px;
        border-radius: 40%;
        background-color: #f06f;
        padding: 1px 1px 4px 1px;
        font-size: 11px;
        font-size: 0.78571rem;
        color: white;
        text-align: center;
    }

    .profile-votes2 {
        width: 30%;
    }
}
.profile-subjects3 {
    display: inline-flex;
    min-width: 0px !important;
    height: 13px;
    border-radius: 5px;
    box-shadow: 1px 2px 2px 2px #efeeee;
    padding: 0px;
    margin: 2px 4px 5px 0px;
    contain: layout;
    box-sizing: border-box;
    text-align: left;
    cursor: pointer;

    .profile-languages2 {
        font-size: 10px !important;
        margin-right: 5px;
        margin-left: 3px;
        margin-top: 0px;
        word-break: initial;
    }

    .ding-section-votes {
        top: 0%;
        width: 22px;
        height: 16px;
        border-radius: 40%;
        background-color: #f06f;
        padding: 1px 1px 4px 1px;
        font-size: 11px;
        font-size: 0.78571rem;
        color: white;
        text-align: center;
    }

    .profile-votes2 {
        width: 30%;
    }
}

.profile-subjects4 {
    display: flex;
    min-width: 0px !important;
    height: 20px;
    border-radius: 9px;
    box-shadow: 1px 2px 2px 2px #efeeee;
    padding: 0;
    margin: 0px 10px 15px 3px;
    background: white;
    contain: layout;
    box-sizing: border-box;
    text-align: left;
    cursor: pointer;
    float: left;
    border: 2px solid #f06;
    color: #f06 !important;
    font-family: Source Sans Pro;

    .profile-languages2 {
        font-size: 11px;
        margin-right: 3px;
        margin-left: 8px;
        margin-top: 0px !important;
        color: #f06 !important;
        word-break: initial;
    }

    .ding-section-votes {
        top: -1px;
        left: 2px;
        width: 21px;
        height: 19px;
        border-radius: 40%;
        background-color: #f06f;
        padding: 2px 1px 4px 1px;
        font-size: 11px;
        font-size: 0.78571rem;
        color: white;
        text-align: center;
    }

    .profile-votes2 {
        width: 30%;
    }
}


.container-exchange-like {
    display: block;
    position: absolute;
    bottom: -35px;
    width: 100%;
    height: 1px;

    .container-ex-bubble {
        position: absolute;
        right: 0px;
        bottom: 0px;
        z-index: 2;
        width: 63px;

        .dingLike_city {
            width: 45px;
            height: 45px;
            border-radius: 100%;
            border: 5px solid #ff609f;
            position: absolute;
            bottom: 32px;
            z-index: 3;
            right: 11px;
        }
    }

}

.container-exchange-like .fishing-net4 {
    cursor: pointer;
    background-color: #eee;
    width: 67px;
    height: 68px;
    position: absolute;
    right: 0px;
    bottom: 20px;
    border-radius: 65px 0px 0px 65px;
    z-index: 1;
}

.ding-filters {
    width: 90%;
    height: 14%;
    text-align: left;
    display: inline-flex;
}




/*  STYLES FOR BROWSE MEDIA BOX */

.upload-box {
    width: 25%;

    input {
        width: 0px;
        height: 0px;
    }
}

.container-media {
    padding: 0px !important;
    margin: 0px !important;
    contain: strict;
}

.browse-image {
    height: 110px;
    width: 150px;
    padding-right: 0px;
}

.crypto-image {
    height: 111px;
    width: 150px;
    padding-right: 0px;

    img {
        height: 110px;
    }
}

.js-crypto-payment2 {
    img {
        width: 90px;
        height: 90px;
    }
}

.full-background-browse {
    width: 100%;
    padding-bottom: 56.25%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.browse-box {
    padding-left: 0px;
    padding-right: 0px;

   .mobile {
        padding-top: 4px;
    }

    .js-rp-image {
        margin-left: 18px;
    }

}


.cover-image {
    width: 100%;
    height: 100%;
    border-radius: 12px 5px 5px 12px;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    overflow: auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.browse-media-body {
    padding: 9px 7px 0px 6px;

    h4 {
        font-size: 1.2rem;
        padding-left: 1px;
    }
}

.browse-media-txt {
    text-align: left;
}

.browse-media-vote {
    display: none;
}


.link-to-external {
    color: #57b2b7;
    cursor: pointer;
}

.personTalk {
    margin: 10px 5px 5px 5px !important;
    padding: 0px !important;
    width: 35px;
    position: absolute;
    z-index: 99;

    .person__image {
        width: 35px;
        height: 35px;
    }
}

.personExchange {
    margin: 4px 0px 0px 4px !important;
    padding: 0px !important;
    width: 40px;
    position: absolute;
    z-index: 4;
    height: 40px;

    .person__image {
        width: 35px;
        height: 35px;
        border: 2px solid white;
    }
}

.person2 {
    margin: 0px 0px 0px 7px!important;
    width: 120px;
    height: 110px;
    display: table-cell;
    vertical-align: middle;
}

.person3 {
    width: 100%;
    margin: 0px 0px 4px 2px !important;
    padding: 0px !important;
    display: flex;
    align-items: center;

    h4 {
        margin-left: 10px;
    }
}

.person__image {
    width: 90px;
    height: 90px;
}

.person__image2 {
    width: 44px;
    height: 44px;
    object-fit: cover;
    border-radius: 100%;
}

.media {
    padding: 7px 0px 5px 0px;
    margin-top: 0px !important;
    min-height: 110px;
    max-height: 110px;
    width: 100%;
    /* display: inline-table; */
    display: flex;
    /* aquii */
}

.mobile {
    cursor: pointer;
}

.eyes-image {
    border-radius: 18px;
    top: 0;
    left: 0;
    overflow: auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.eyes-clickable {
    cursor: pointer;
}

.container-browse-icons {
    display: flex;
    height: 33px;
    justify-content: flex-start;
    align-items: center;
    padding-left: 10px;

    a {
        margin-left: 13px;
        font-weight: 700;
        font-size: 1.1rem;
        color: #f06;
        align-self: center;
    }
}

.icon-browse-dingpage {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 30px;

    svg {
        position: relative;
        width: 29px;
        height: 30px;
    }
}



/*  STYLES FOR CRYPTO BOX */

.cryptobox {
    height: 164px;
    border-radius: 28px;
    box-shadow: 2px 2px 6px #dcdbdba3;
    padding: 0px 5px;
    margin: 20px auto;
    text-align: center;
    contain: content;
    box-sizing: border-box;


    .cryptobox-inputs {
        display: flex;
    }

    .crypto-inp {
        border-radius: 5px;
        max-width: 65px;
        max-height: 25px;
        margin: 2px;
        border: 1px solid #e4e2e2;
        font-size: 12px;
    }

    .crypto-inp2 {
        border-radius: 5px;
        max-width: 58px;
        max-height: 25px;
        margin: 2px;
        border: 1px solid #e4e2e2;
    }

    .crypto-descrp {
        border: 1px solid #e4e2e2;
        border-radius: 8px;
        resize: none;
        width: 327px;
        margin-bottom: 4px;
    }

    p {
        padding-top: 4px;
        font-size: 12px;
    }

    &__container {
        width: 100%;
        display: inline-block;

        .container-divs {
            width: 80%;
            display: grid;
            padding-top: 14px;
            padding-left: 10px;
        }
    }

    .fishing-right {
        width: 100%;
    }

    .ding-send-crypto {
        width: 56px;
        height: 56px;
        border-radius: 100%;
        background-color: #ef0759;
        margin-right: 3px;
        cursor: pointer;
        position: absolute;
        bottom: 45px;
        right: 5px;
    }

    .fishing-net2 {
        cursor: pointer;
        background-color: #eee;
        width: 108px;
        height: 108px;
        position: absolute;
        bottom: 16px;
        right: -20px;
        border-radius: 65px 65px;
        z-index: -1;
    }

    .ding-filters {
        width: 90%;
        height: 14%;
        text-align: left;
        display: inline-flex;
    }

}


/* STYLE FOR ROLE PROFILES */

.person4 {
    margin: 0 auto;
    padding: 0px !important;
    display: flex;
    cursor: pointer;
    width: fit-content;
    width: 62px;
    height: 62px;
}

.person4_image {
    margin: 0px 0px 0px 0px !important;
}

.person5 {
    margin-right: 8px;
    width: fit-content;
    padding: 0px !important;
    cursor: pointer;
    min-width: 46px;
}

.faButton {
    color: #f06f;
    font-size: 30px;
    position: absolute;
    left: 37px;
    top: 17px;
}


.remodal2 {
    max-width: 310px;
    border-radius: 25px;
    padding: 30px;
}

.remodal3 {
    max-width: 310px;
    border-radius: 25px;
    padding: 5px 30px 5px 30px;
}

.rp-style {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding: 0px 0px 0px 12px;
    width: 100%;
    height: 65px;
    box-shadow: 2px 2px 6px #dcdbdba3;
    border-radius: 15px;
    margin: 0 auto;
    margin-bottom: 5px;
    cursor: pointer;
    text-decoration: none;
}

.rp-link-style {
    color: #2bbcf4;
    font-size: 11px;
    display: flex;
    padding-top: 2px;

    svg {
        width: 13px;
        height: 13px;
        margin-right: 3px;
    }
}

.rp-link-style:hover {
    text-decoration: none;
}

.rp-text {
    width: 61%;
    align-items: center;
    padding-top: 13px;

    h4 {
        font-size: 13px;
        font-weight: bolder;
        text-align: center;
    }
}


.rp-start {

    display: flex;
    align-items: center;

    h3 {
        color: #c7c7c7;
    }
}

.rp-votes {
    width: 45px;
    height: 45px;
    border-radius: 100%;
    border: 3px solid #f06f;
    display: flex;
    margin-top: 10px;
    justify-content: center;
    align-content: center;
    flex-direction: column;
}

.personRP {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 0px 0px 3px 10px !important;

    img {
        height: 40px;
        width: 40px;
        margin-right: 7px;
    }
    h4 {
        font-size: 12px !important;
    }
}

.container-RP-user {
    display: flex;
}


.rp-icons {

    display: flex;
    margin: 0px 0px 0px 10px;
    align-items: center;
}

.rp-icon-text {
    margin-right: 8px;
    margin-left: 2px;
    font-size: 11px;
}

.container-rp {
    margin: 0 auto;
    border-radius: 26px;
    padding-bottom: 15px;
    min-height: 525px;

    .channel-community-icons {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        height: 1px;
        padding-right: 20px;
    }

    .js-change-profileShow {
        width: 50%;
        text-align: center;
        padding-top: 3px;
    }

    .active-rp-dashboard {
        box-shadow: 3px 0px 9px 2px #dcdbdba3;
        border: 1px solid #d7d5d5;
        z-index: 4;
    }


    /* switch css */

    .container-of-switch {
        position: absolute;
        z-index: 99999;
        right: 9px;
        bottom: -4px;
    }

    .switchToggle {
        font-family: Source Sans Pro;
    }

    .switchToggle input[type=checkbox] { 
        height: 0;
        width: 0;
        visibility: hidden;
        position: absolute;
    }
    .switchToggle label {
        cursor: pointer;
        text-indent: -9999px;
        width: 100px;
        max-width: 100px;
        height: 25px;
        background: #d1d1d1;
        display: block;
        border-radius: 100px;
        position: relative;
        line-height: 1.6;
    }

    .switchToggle label:after {
        content: '';
        position: absolute;
        top: 1px;
        left: 2px;
        width: 23px;
        height: 23px;
        background: #fff;
        border-radius: 90px;
        transition: 0.3s;
        line-height: 1.6;
    }

    .switchToggle input:checked + label, .switchToggle input:checked + input + label  {
        background: #47b8ff;
    }

    .switchToggle input + label:before, .switchToggle input + input + label:before {
        content: 'Channel';
        position: absolute;
        top: 0px;
        left: 35px;
        width: 25px;
        height: 25px;
        border-radius: 90px;
        transition: 0.3s;
        text-indent: 0;
        color: #fff;
        line-height: 1.6;
    }

    .switchToggle input:checked + label:before, .switchToggle input:checked + input + label:before {
        content: 'Group';
        position: absolute;
        top: 0px;
        left: 10px;
        width: 25px;
        height: 25px;
        border-radius: 90px;
        transition: 0.3s;
        text-indent: 0;
        color: #fff;
        line-height: 1.6;
    }

    .switchToggle input:checked + label:after, .switchToggle input:checked + input + label:after {
        left: calc(100% - 1px);
        transform: translateX(-100%);
    }
    .switchToggle label:active:after {
        width: 60px;
    } 
    .toggle-switchArea {
        margin: 10px 0 10px 0;
    }

}

.container-rp-imageText {
    width: 100%;
    display: grid;
    padding-bottom: 56%;
}

.js-profile-image-dingPage {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.container-rp-title {

    position: absolute;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 33px;
    display: flex;
    font-family: Source Sans Pro;

    .personTalk {
        margin: 10px 0px 0px 5px !important;
        padding: 0px !important;
        width: 35px;
    }

    h1 {
        font-size: 2rem;
        font-weight: bolder;
        color: white;
        margin: 3px 0px 0px 4px;
        font-family: Roboto;
    }
}

.container-rp-text {
    display: inline-block;
    vertical-align: middle;
    justify-content: center;
    margin: 0px 0px 0px 20px;
}

.container-rp-text h3 {
    font-weight: bolder;
    font-size: 15px;
    padding: 0;
    margin: 0;
}

.rp-img {
    height: 260px;
    width: 100%;
    object-fit: cover;
    margin: 0 auto;  
}

.container-rp-like {

    display: block;
    position: absolute;
    bottom: 6px;
    width: 100%;
    height: 10px;

    h1 {
        font-family: Roboto;
    }

    .container-ex-bubble {
        position: absolute;
        right: 0px;
        bottom: 0px;
        z-index: 2;
        width: 20%;
    }

    .rp-txt {
        margin: 9px 0px 6px 5px;
        width: 85%;
        font-size: 1.4rem;
        font-family: Source Sans Pro;
    }

    .dingLike_city {
        width: 45px;
        height: 45px;
        border-radius: 100%;
        border: 5px solid #ff609f;
        position: absolute;
        bottom: 23px;
        z-index: 3;
        right: 22px;
    }

}

.talk-comments-rp {
    display: flex;
    top: 6px;
    right: 5px;
    position: absolute;
    color: white;
    justify-content: center;
    align-items: center;

    svg {
        position: relative;
        width: 23px !important;
        height: 23px !important;
    }
}

.rp-full-text {
    display: flex;
    width: 100%;

    svg {
        width: 23px;
        margin-left: 5px;
    }
}

.container-rp-like .fishing-net4 {
    cursor: pointer;
    background-color: #eee;
    width: 75px;
    height: 58px;
    position: absolute;
    right: 0px;
    bottom: 16px;
    border-radius: 65px 0px 0px 65px;
    z-index: 1;
}

.rp-txt {
    margin: 4px 0px 30px 5px;
    width: 85%;
    font-size: 1.4rem;
    font-family: Source sans pro;
}

/* STYLES FOR REMODAL OF SUBJECTS (KEYS) AND OTHER REMODALS */

.matching {
    width: 70%;
}

.none-matching {
    width: 70%;
}


@media screen and (max-width: 991px) {
    .toLeft {
        text-align: center !important;
    }

    .toRight {
        text-align: center !important;
    }   
}

.no-events {
    width: 70%;
    text-align: center;
}

.no-events-title {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
}

.noEvents-team {
    font-weight: bold;
    text-align: left;
}


.new-event {
    width: 400px;
    max-width: 100%;
    border-radius: 0;
    border: 1px solid #636363;
    padding: $spacing-half;
    text-align: center;

    &__close {
        position: absolute;
        top: 0;
        right: 5px;
        font-size: 20px;
    }

    &__content {
        margin: $spacing 0;
    }

    .radio {
        display: flex;
        flex-wrap: wrap;

        label {
            width: 70px;
        }
    }

    h4 {
        color: #999;
        margin: 0;
        padding: 0 0 0 $spacing-half;
        font-size: 16px;
        text-align: left;
    }

    h2 {
        font-size: 20px;
        text-align: center;
        font-weight: $font-bold;
        margin-bottom: $spacing;
    }
}

.fix-bar {
    height: 35px;
    margin-top: 0px;
}


/* STYLES FOR PROFILES */

.profile-styles .upload-box {
    width: 30%;
    font-size: 20px;
    height: 100%;
    margin: 0 auto;
    padding-top: 5px;
}

.profile-subs {
    display: flex;
    margin-left: 30px;
    padding-left: 30px;
    position: absolute;
    bottom: 3px;
}

.subs-rp {
    margin-left: 0px;
    padding-left: 0px;
    align-content: flex-start;
    position: initial;
    margin-top: -7px;
}

.profile-styles .ding-inputs {
    width: 55%;
    height: 100%;
    text-align: center;
}

.profile-styles .upload-button {
        color: #909090;
        width: 100%;
        height: 31%;
        border-radius: 28px;
        background-color: white;
        font-size: 35px;
        font-weight: lighter;
        text-align: center;
        cursor: pointer;
        display: inline-grid;
        padding-top: 5px;
}

.profile-styles .select-type-rp {
        width: 100%;
        height: 50%;
        display: inline-block;
}

.location {
    display: inline-flex;
    font-size: 14px;
    padding: 1px 4px 4px 2px;
    color: #909090;
    cursor: pointer;
    height: 24px;

    i {
        font-size: 20px;
        color: #909090;
        margin-right: 15px;
    }
}
.roaming {
    display: inline-flex;
    font-size: 14px;
    padding: 1px 4px 4px 2px;
    color: #909090;
    cursor: pointer;
    height: 24px;

    i {
        font-size: 20px;
        color: #909090;
        margin-right: 15px;
    }
}

.rp-help {
    height: 25px;

    i {
        font-size: 20px;
        color: #909090;
        margin-left: 10px;
        cursor: pointer;
    }
}

.type-selected {
    border: 1px solid #d6d6d6;
    border-radius: 5px;
}

.general-button-back {
    margin: 2px 0px -5px 0px;
    border: 1px solid #d7e3ec;
    border-bottom: none;
    background: white;
    /* box-shadow: 1px 1px 20px 14px #ececec75; */
    border-radius: 10px 10px 0px 0px;
    min-height: 38px;
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
    z-index: 15;

    svg {
        position: relative;
        width: 22px;
        height: 18px;
        margin-left: 5px;
        margin-top: 10px;
    }

    h4 {
        font-size: 16px;
        font-family: Source Sans Pro;
    }

}

svg.back-to-main {
    transform: rotate(-180deg) scale(1.0, 1.0);
    width: 25px;
    height: 25px;
    margin-left: 5px;
    margin-top: 5px;
}



/* CSS FOR 1 MINUTE TOUR */
/* CSS FOR 1 MINUTE TOUR */

.welcome-remodal {
    font-family: Source sans pro;
    max-width: 360px;
    min-width: 360px;
    padding: 5px 20px 20px 20px;
    text-align: left;
    contain: content;
    border-radius: 12px !important;
    top: -56px;
    color: #ff0066;

    h2 {
        color: #ff0066;
        font-weight: 900;
        font-family: Source sans pro;
    }

    p {
        font-size: 17px;
        color: #ff0066;
        margin: 0px;
    }

    .remodal-buttons {
        width: 100%;
        height: 30px;
        position: relative;

        svg {
            width: 60px;
            height: 60px;
        }
    }

    .black-link {
        font-size: 17px;
        color: #ff0066;
        text-decoration: none;
        border-bottom: 1px solid #ff3c8a;
        position: absolute;
        right: 0;
        font-family: Source sans pro;
        font-weight: 700;
    }

    .red-link {
        font-size: 1rem;
        font-family: Source sans pro;
        color: #ff0066;
        text-decoration: none;
        margin-top: 10px;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 0;

        svg {
            width: 30px;
            height: 21px;
        }
    }

}

.welcome-remodal2 {
    font-family: Source sans pro;
    max-width: 405px;
    min-width: 360px;
    padding: 20px 20px 20px 20px;
    text-align: left;
    contain: initial;
    border-radius: 12px !important;
    top: -130px;
    color: #ff0066;

    h2 {
        color: #ff0066;
        font-weight: 900;
        margin-top: 0px;
        font-family: Source sans pro;
    }

    p {
        font-size: 17px;
        color: #ff0066;
        margin: 0px;
        font-family: Source sans pro;
    }

    .remodal-buttons {
        width: 100%;
        height: 30px;
        position: relative;
        display: flex;
    }

    .red-link {
        font-size: 17px;
        color: #ff0066;
        text-decoration: none;
        border-bottom: 1px solid #ff3c8a;
        position: absolute;
        right: 0;
    }

    .black-link {
        font-size: 1rem;
        font-family: Source sans pro;
        font-weight: 700;
        color: #686868;
        text-decoration: none;
        margin-top: 10px;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 0;

        svg {
            width: 30px;
            height: 21px;
        }
    }

    .arrow-down {
        display: flex;
        justify-content: center;

        svg {
            position: absolute;
            width: 70px;
            height: 80px;
            top: -5px;
        }
    }
}

.filter-by-guild {
    max-width: 440px;
    min-width: 360px;
    padding: 30px;
    text-align: left;
    contain: content;
    border-radius: 12px !important;
    contain: inherit;
    color: #ff0066;

    .welcome-title-remodal {
        margin: 5px;
        font-family: Source sans pro;
    }

    .title-flex {
        font-family: Source sans pro;
        display: flex;
        justify-content: flex-start;

        svg {
            width: 60px;
            height: 60px;
        }

        h4 {
            display: flex;
            align-items: center;
            color: #ff0066;
            font-size: 34px;
            font-weight: 900;
            font-family: Source sans pro;
        }
    }

    .title-flex-2 {
        display: flex;
        justify-content: flex-start;
        font-family: Source sans pro;

        svg {
            height: 75px;
            width: 124px;
            margin-left: 12px;
            margin-right: 10px;
        }
    }

    .first-row {
        font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
        display: flex;

        .badges {
            margin: 3px 3px;
        }
    }

    .subjects-flex {
        display: grid;
        justify-content: center;

        p {
            font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
        }
    }

    .image-flex {
        width: 100px;
        height: 65px;
        background: gray;
        margin-right: 17px;
    }

    p {
        font-size: 17px;
        color: #ff0066;
        margin: 0px;
        font-family: Source sans pro;
    }

    .remodal-buttons {
        width: 100%;
        height: 30px;
        position: relative;
        display: flex;
    }

    .image-centered {
        display: flex;
        justify-content: center;

        svg {
            width: 140px;
            height: 150px;
        }
    }

    .red-link {
        font-size: 17px;
        color: #ff0066;
        text-decoration: none;
        border-bottom: 1px solid #ff3c8a;
    }

    .black-link {
        font-size: 1rem;
        font-family: Source sans pro;
        font-weight: 700;
        color: #686868;
        text-decoration: none;
        margin-top: 10px;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 0;

        svg {
            width: 30px;
            height: 21px;
        }
    }

    .result {
        height: 27px;
        min-width: 65px;
        border-radius: 13px;
        border: 3px solid #e91e63;
        box-shadow: 1px 2px 2px 2px #efeeee;
        padding: 0px;
        margin: 0px 0px;
        background-color: #fff;
        color: #ff0066;
        box-sizing: border-box;
        text-align: center;
        cursor: pointer;
        font-weight: bolder;

        .result-input {
            font-size: 1.05rem;
            margin: 0px 5px 0px 5px;
            height: 100%;
        }
    }

    .big-image {
        width: 175px;
        height: 200px;
        background: #d0cece;
        margin: 15px;
    }

    .arrow-for-keybar {
        display: none;
    }

    .arrow-for-dings {
        display: none;
    }

    @media (min-height: 650px) {
        .arrow-for-keybar {
            display: block;
            position: absolute;
            z-index: 200000;
            transform: rotate(270deg);
            width: 74px;
            height: 74px;
            top: -85px;
            left: -156px;
        }
    }

    @media (min-height: 750px) {
        .arrow-for-keybar {
            top: -84px;
        }
    }
    @media (min-height: 900px) {
        .arrow-for-keybar {
            top: -82px;
        }
    }
    @media (min-height: 930px) {
        .arrow-for-keybar {
            top: -94px;
        }
    }
    @media (min-height: 960px) {
        .arrow-for-keybar {
            top: -114px;
        }
    }
    @media (min-height: 990px) {
        .arrow-for-keybar {
            top: -128px;
        }
    }
    @media (min-height: 1020px) {
        .arrow-for-keybar {
            top: -143px;
        }
    }
    @media (min-height: 1050px) {
        .arrow-for-keybar {
            top: -152px;
        }
    }
    @media (min-height: 1080px) {
        .arrow-for-keybar {
            top: -167px;
        }
    }

}

.vote-part-tour {
    max-width: 440px;
    min-width: 360px;
    padding: 30px;
    text-align: left;
    contain: content;
    border-radius: 12px !important;
    contain: inherit;
    color: #ff0066;

    .welcome-title-remodal {
        margin: 5px;
        font-family: Source sans pro;
    }

    .title-flex {
        font-family: Source sans pro;
        display: flex;
        justify-content: flex-start;
        margin-bottom: 15px;

        svg {
            width: 60px;
            height: 60px;
        }

        h4 {
            display: flex;
            align-items: center;
            color: #ff0066;
            font-size: 34px;
            font-weight: 900;
            font-family: Source sans pro;
        }
    }

    .title-flex-2 {
        display: flex;
        justify-content: flex-start;
        font-family: Source sans pro;

        svg {
            height: 75px;
            width: 124px;
            margin-left: 12px;
        }
    }

    .image-flex {
        width: 100px;
        height: 65px;
        background: gray;
        margin-right: 17px;
    }

    p {
        font-size: 17px;
        color: #ff0066;
        margin: 0px;
        font-family: 'Source sans pro';
    }

    .remodal-buttons {
        width: 100%;
        height: 30px;
        position: relative;
        display: flex;
    }

    .image-centered {
        display: flex;
        justify-content: center;

        svg {
            width: 100%;
            height: 90px;
        }

        svg circle {
            fill: #f06 !important;
        }
    }

    .black-link {
        font-size: 1rem;
        font-family: Source sans pro;
        font-weight: 700;
        color: #686868;
        text-decoration: none;
        margin-top: 10px;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 0px;

        svg {
            width: 30px;
            height: 21px;
        }
    }

    .big-image {
        width: 175px;
        height: 200px;
        background: #d0cece;
        margin: 15px;
    }

}

.easy-democracy {
    max-width: 440px;
    min-width: 360px;
    padding: 30px;
    text-align: left;
    contain: content;
    border-radius: 12px !important;
    contain: inherit;
    color: #ff0066;

    .welcome-title-remodal {
        margin: 5px;
        font-family: Source sans pro;
    }

    .title-flex {
        font-family: Source sans pro;
        display: flex;
        justify-content: flex-start;

        svg {
            width: 60px;
            height: 60px;
        }

        h4 {
            display: flex;
            align-items: center;
            color: #ff0066;
            font-size: 34px;
            font-weight: 900;
            font-family: Source sans pro;
        }
    }

    .title-flex-2 {
        display: flex;
        justify-content: flex-start;
        font-family: Source sans pro;

        svg {
            height: 75px;
            width: 124px;
            margin-left: 12px;
        }
    }

    .first-row {
        font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
        display: flex;

        .badges {
            margin: 3px 3px;
        }
    }

    .subjects-flex {
        display: grid;
        justify-content: center;

        p {
            font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
        }
    }

    .image-flex {
        width: 100px;
        height: 65px;
        background: gray;
        margin-right: 17px;
    }

    p {
        font-size: 17px;
        color: #ff0066;
        margin: 10px;
        font-family: 'Source sans pro';
    }

    .remodal-buttons {
        width: 100%;
        height: 30px;
        position: relative;
        display: flex;
    }

    .image-centered {
        display: flex;
        justify-content: center;

        svg {
            width: 140px;
            height: 150px;
        }
    }

    .red-link {
        font-size: 17px;
        color: #ff0066;
        text-decoration: none;
        border-bottom: 1px solid #ff3c8a;
    }

    .black-link {
        font-size: 1rem;
        font-family: Source sans pro;
        font-weight: 700;
        color: #686868;
        text-decoration: none;
        margin-top: 10px;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 0px;

        svg {
            width: 30px;
            height: 21px;
        }
    }

    .result {
        height: 27px;
        min-width: 65px;
        border-radius: 13px;
        border: 3px solid #e91e63;
        box-shadow: 1px 2px 2px 2px #efeeee;
        padding: 0px;
        margin: 0px 0px;
        background-color: #fff;
        color: #ff0066;
        box-sizing: border-box;
        text-align: center;
        cursor: pointer;
        font-weight: bolder;

        .result-input {
            font-size: 1.05rem;
            margin: 0px 5px 0px 5px;
            height: 100%;
        }
    }

    .big-image {
        width: 175px;
        height: 200px;
        background: #d0cece;
        margin: 15px;
    }

    .arrow-for-keybar {
        display: none;
    }

    .arrow-for-dings {
        display: none;
    }

    @media (min-height: 650px) {
        .arrow-for-keybar {
            display: block;
            position: absolute;
            z-index: 200000;
            transform: rotate(270deg);
            width: 74px;
            height: 74px;
            top: -43px;
            left: -156px;
        }
    }

    @media (min-height: 750px) {
        .arrow-for-keybar {
            display: block;
            position: absolute;
            z-index: 200000;
            transform: rotate(270deg);
            width: 74px;
            height: 74px;
            top: -82px;
            left: -154px;
        }
    }
    @media (min-height: 900px) {
        .arrow-for-keybar {
            display: block;
            position: absolute;
            z-index: 200000;
            transform: rotate(270deg);
            width: 74px;
            height: 74px;
            top: -162px;
            left: -154px;
        }
    }
}

/* SOME EXTRA ADJUSTMENTS TO THE MODAL POSITION */

@media (min-height: 650px) {
    .final-tour-remodal {
        top: -70px;
    }
    .welcome-remodal2 {
        top: -95px;
    }
    .welcome-remodal {
        top: -95px;
    }
    .filter-by-guild {
        top: 40px;
    }
    .easy-democracy {
        top: 0px;
    }
}

@media (min-height: 750px) {
    .final-tour-remodal {
        top: -110px;
    }
    .welcome-remodal2 {
        top: -133px;
    }
    .welcome-remodal {
        top: -135px;
    }
    .filter-by-guild {
        top: 0px;
    }
    .easy-democracy {
        top: -36px;
    }
}
@media (min-height: 900px) {
    .final-tour-remodal {
        top: -180px;
    }
    .welcome-remodal2 {
        top: -210px;
    }
    .welcome-remodal {
        top: -213px;
    }
    .filter-by-guild {
        top: -77px;
    }
    .easy-democracy {
        top: -113px;
    }
}

.final-tour-remodal {
    font-family: Source sans pro;
    max-width: 480px;
    min-width: 360px;
    padding: 35px;
    text-align: left;
    contain: content;
    border-radius: 12px !important;
    contain: inherit;
    color: #ff0066;


    .final-tour-flex {
        display: flex;
        justify-content: middle;
    }

    .middle1 {
        display: inline-flex;
        justify-content: center;
        align-items: center;

        img {
            height: auto;
        }
    }

    .remodal-buttons {
        width: 100%;
        height: 30px;
        position: relative;
        display: flex;
    }

    .black-link {
        font-size: 1rem;
        font-family: Source sans pro;
        font-weight: 700;
        color: #686868;
        text-decoration: none;
        margin-top: 10px;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 0px;

        svg {
            width: 30px;
            height: 21px;
        }
    }

    p {
        font-size: 20px;
        color: #ff0066;
        margin: 0px;
        font-family: 'Source sans pro';
    }

    .the-crutial-6 {
        max-width: 200px;
    }

    .middle2 {
        width: auto;
        align-content: center;
        display: grid;
        justify-content: end;
    }

}


/* CSS FOR MOBILE */
/* CSS FOR MOBILE */
/* CSS FOR MOBILE */



@include screen-xs() {

    .water-mark {
        display: none;
    }

    .only-devices {
        display: block !important;
        height: 1px;
    }

    .not-for-mobile {
        display: none !important;
    }

    .paddings {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .crypto-image {
        img {
            height: 100%;
        }
    }

    .container {
        padding: 0;
        overflow: hidden;
    }

    .col-xs-12 {
        padding: 0;
    }

    /* KEYBAR STYLES */

    .ding-search2 {
        margin: 0px;
    }

    .no-lines {
        width: auto;
    }

    .only-mobile {
        display: none;
        height: 100%;
    }

    .only-pc {
        display: none;
    }

    svg.send-keybar-mobile {
        width: 30px;
        height: 30px;
    }

    .big-container {
        display: inline-block;

        .container-select-ding {
            width: 40%;
        }
    }

    .badges {
        display: inline-block;
    }
    .result {
        width: max-content;
    }

    .container-send-div {
        .fishing-net-keybar {
            right: -10%;
        }
    }

    /* MAIN-ICONS */
    .js-main-icons {
        margin-top: 15px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .meet-dings {

        .form-group.label-floating .form-control {
            min-width: 20%;
        }
    }


    /* OTHERS */

    .profile-subjects4 {
        .ding-section-votes {
            top: -2px;
            left: 1px;
            width: 21px;
            height: 20px;
            border-radius: 42%;
            background-color: #f06f;
            padding: 2px 1px 4px 1px;
            font-size: 11px;
            font-size: 0.78571rem;
            color: white;
            text-align: center;
        }
    }


    .liked {

        p {
            width: 68% !important;
            height: 83%;
        }
    }

    .roleplay-remodal-inside {
        img {
            width: 100%;
        }
    }

    .mobileP {
        text-align: center !important;
        font-size: 13px !important;
        margin-top: 6px !important;
    }
    .mobile-txt {
        max-height: 107px;
    }

    .profile-subjects2 {
        float: left;
    }

    .dingLike_city {
        margin: 0px 5px 5px 6px;
        z-index: 2;

        p {
            padding-bottom: 2px;
        }
    }

    .ding-style {
        height: auto;
        margin: 15px auto;
    }

    .ding-style-mobile {
        max-height: none;
        min-height: 335px;

        .container-media {
            display: inline-grid;
        }

        .media {
            width: 100%;
            min-height: 335px;
        }

        .media-body {
            max-height: 145px;
            margin-bottom: 27px !important;
        }

        .media-body p {
            width: 80%;
            white-space: break-spaces;
            max-height: 70px;
            margin: 0px;
        }

        .browse-image {
            width: 100%;
            min-height: 195px;
        }

        .cover-image {
            position: relative;
            width: 100%;
            min-height: 200px;
            padding-bottom: 1.25%;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            border-radius: 5px;
        }

        .icons {
            padding-bottom: 0px;
            bottom: -10px;
        }

        .button-send2 {
            width: 66px;
            margin: 0 !important;
            padding: 0 !important;
            position: absolute;
            top: 40px;
            right: 2px;
            height: 88px;
        }

        .dingLike_city {
            bottom: 3px !important;
            z-index: 99 !important;
            right: 11px !important;
            top: 15px;
        }

        .fishing-net3 {
            position: absolute;
            top: 9px;
            right: -3px;
            width: 70px;
            height: 57px;
        }

    }

    .ding-send {
        margin-bottom: 15px;
    }

    .ding-user-image {
        display: none;
    }

    .media-body {
        width: 74%;
        height: 100%;
        display: table-cell;
        margin-top: 4px;
        margin-bottom: 4px;


        .media-text-for-main {
            font-size: 14px !important;
            margin: 3px 56px 0px 50px;
            font-family: Source sans pro;
        }
    }

    /* RELATED TO MOBILE USER'S IMAGE */

    .person5 {
        margin: 0px 7px 0px 0px;
        padding: 0px !important;
        cursor: pointer;
        min-width: 43px;
        height: 43px;
        width: 43px;
        align-self: center;
    }

    .personTalk {
        margin: 7px 7px 5px 11px !important;
        padding: 0px !important;
        width: 29px;
        position: absolute;
        z-index: 99;
    }

    /* This is for some dings */
    .imgMobile {
        width: 40px !important;
        height: 40px !important;
    }

    /* This is for other dings that use this class */
    .image-mobile {
        display: block;
        margin-top: 40px;
        margin-right: 4px;
    }

    .person__image {
        width: 40px;
        height: 40px;
    }

    .person__image2 {
        width: 40px;
        height: 40px;
    }

    .person {
        width: 45px !important;
        padding: 0;
    }

    .person3 {

        margin: 0px !important;
        padding: 0px !important;
        display: inline-flex;
        width: auto !important;

        .imgMobile {
            width: 30px !important;
            height: 30px !important;
            margin-right: 5px;
        }

        h4 {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            margin: 0;
        }
    }

    .person__languages {
        display: none;
    }

    /* GENERAL STYLES FOR MOBILE */

    .key-bar-spacing {
        margin-bottom: 25px;
        margin-top: 25px;        
    }

    .container-exchange2 {
        min-height: 460px;
        padding-bottom: 0px;
        margin-bottom: 0px;
    }

    .subs-rp {
        margin-top: -4px;
    }

    .mobile {
        margin-top: 6px;
        display: inline-block;
        padding-top: 10px;
        padding-bottom: 5px;
    }

    /* STYLES FOR TALK DINGS IN MOBILE */

    .ding-talk-mobile {
        display: flex;
        width: 100%;
    }

    .delete-ding {
        right: 25px;
    }

    .noEvents-team {
        font-size: 15px;
    }

    .team {
        margin-top: 4px !important;
    }

    .city__facebook-banner {
        font-size: 12px !important;
    }

    .fishing-right {
        width: 21%;
    }

    .fishing-net {
        display: none;
    }

    .fishing-net2 {
        right: -12%;
    }

    .fishing-net3 {
        position: absolute;
        top: -1px;
        right: -3px;
        width: 70px;
        height: 57px;
    }

    .exchange {
        width: 95%;

        h3 {
            margin: 6px 4px 7px 7px;
        }

        &__container {
            .container-shadow {
                position: absolute;
                width: 62px;
                height: 58px;
                border-radius: 30px 0px 0px 30px;
                background-color: #f3f2f2;
                top: 5px;
                z-index: 1;
                right: 0px;
            }
        }

        &__image {
            width: 100%;
            height: auto;
            max-height: 200px;

            img {
                height: auto;
                max-height: 200px;
            }
        }
    }



    .container-exchange {
        border-radius: 25px;
        box-shadow: 2px 2px 6px #dcdbdba3;
    }

    .container-exchange-like {
        display: inline-grid !important;
        position: initial;
        height: 1px;

        .container-ex-bubble {
            position: absolute;
            right: 0px;
            bottom: 0px;
        }

        .fishing-net4 {
            width: 71px !important;
            height: 62px !important;
            right: 0px;
            bottom: 19px !important;
            border-radius: 65px 0px 0px 65px;
            z-index: 1;
        }
    }

    .container-exchange-user {
        height: auto;
        display: inline-flex;
    }

    .container-exchange-text {

        width: 100%;
        margin: 0px 0px 4px 6px;
        padding: 0px 8px 0px 0px;
        vertical-align: inherit;
        display: flex;
        text-align: center;
        justify-content: flex-start;

        h3 {
            text-align: center;
            margin: 0;
        }

        p {
            margin-left: 6px;
        }

    }

    .container-exchange-text1 {

        margin: 5px 0px 0px 5px;
        width: 83%;

        h3 {
            font-weight: bolder;
            font-size: 15px;
        }

        h1 {
            font-size: 20px;
            font-weight: bolder;
        }

        p {
            font-size: 14px;
        }
    }


    .talk-comments2 {
        right: 25px;
    }


    .container-exchange-filters {
        display: inline-table;
        width: auto;
        height: auto;

        .container-exchange-subjects {
            text-align: left;
            display: flow-root !important;
        }
    }


    .container-talk-like {
        display: block !important;
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 1px;

        .container-ex-bubble {
            position: absolute;
            right: 10px;
            bottom: 0px;
            padding-bottom: 10px;

            .dingLike_city {
                bottom: 11px !important;
                z-index: 3;
                right: 15px !important;
            }
        }

        .fishing-net5 {
            cursor: pointer;
            background-color: #eee;
            position: absolute;
            width: 91px;
            height: 58px;
            bottom: 9px;
            right: -3%;
            border-radius: 65px 65px;
            z-index: 1;
        }
    }

    .container-exchange-imageText {
        margin-bottom: 0px;
        display: flow-root;
    }

    .fishing-net6 {
        display: block !important;
        cursor: pointer;
        background-color: #eee;
        position: absolute;
        width: 101px;
        height: 86px;
        bottom: -6px;
        right: -7%;
        border-radius: 65px 70px;
        z-index: -1;
    }

    .profile-styles .ding-inputs {
        width: 48%;
    }

    .ding-send2 {
        right: 0px;
    }

    .ding-bar {
        padding: 0px 0px 0px 11px !important;
        width: 100%;
        overflow: hidden;
    }

    /* BROWSE DINGS */

    .browse-box {
        .media {
            overflow: initial;
        }
        .container-media {
            contain: initial;
            margin-bottom: 10px !important;
            overflow: initial;
        }
    }

    .browse-media-body {
        height: 100%;
        width: 100%;
        display: flow-root;
        padding-left: 5px;
        padding-top: 0px !important;
        margin-top: 0px !important;
        padding-right: 0px;
        overflow: initial;

        h4 {
            display: flex;
        }
    }

    .browse-media-txt {
        display: inline-grid;
        width: 100%;
        height: 100px;

        p {
            margin-top: 0px !important;
            margin-left: 0px !important;
        }
    }

    .browse-media-vote {
        display: inline;
    }

    .browse-media-vote2 {
        display: none;
    }

    .ex-img {
        border-radius: 0px;
    }

    .ex-txt {
        margin: 7px 0px 0px 20px;
        width: 80%;
    }

    .media-right-content {
        display: none;
    }

    .button-send {
        width: 60px;
        margin: 0;
        padding: 0;
        position: absolute;
        bottom: -3px;
        right: 10px;
        height: auto;
    }

    .upload-button {
        left: 2px;
    }

    .fix-bar {
        width: 100%;
    }

    .father-subject {
        display: inline-block;
    }

    .flex {
        display: table-caption;
    }

    .hide-if {
        display: none;
    }

    .media-middle {
        vertical-align: bottom;
    }

    .media-body {
        p {
            margin-top: 2px;
            width: 82%;
            padding-bottom: 0px;
        }
    }

    .icons {
        position: absolute;
        padding-bottom: 0px;
        width: 78%;
    }

    .upper-icons {
        display: inline-flex;
    }

    .bottom-icons {
        display: none !important;
    }

    .bottom-icons2 .js-icon-browse {
        display: none !important;
    }

    .bottom-icons2 .js-city-name {
        display: none !important;
    }

    .mini-icon-text {
        margin-bottom: 4px;
        font-size: 14px;
    }

    .button_back {
        display: flex;
        justify-content: initial;
        margin: auto;
    }

    .person4 {
        margin-top: 20px;
        margin-bottom: 20px;
        justify-content: center;
    }

    .rp-img {
        width: 100%;
        border-radius: 0px;
    }
    .rp-text {
        width: 56%;
    }

    .profile-subjects3 {
        padding: 0px 1px 0px 0px;
        margin: 2px 5px 10px 8px;
        float: left;
    }

    .container-rp {
        min-height: 480px;
    }

    .container-rp-like {

        .container-ex-bubble {
            position: absolute;
            right: 11px;
            bottom: 14px;
        }

        .dingLike_city {
            right: 0px;
            bottom: 2px;
        }

        .fishing-net4 {
            cursor: pointer;
            background-color: #eee;
            width: 70px;
            height: 58px;
            position: absolute;
            right: 0px;
            bottom: 14px;
            border-radius: 65px 0px 0px 65px;
            z-index: 1;
        }
    }

    .container-RP-user {
        display: block;
    }

    /* STYLES FOR CRYPTO DINGS */

    .crypto-style {

        .container-media {
            display: flex;
        }

        .crypto-image {
            width: 70px;
        }

        .button-send2 {
            width: 66px;
            margin: 0;
            padding: 0;
            position: absolute;
            top: 17px;
            right: 12px;
            height: 58px;
        }

        .fishing-net3 {
            position: absolute;
            top: 16px;
            right: 18px;
            width: 65px;
            height: 57px;
        }
        .icons {
            position: -webkit-sticky;
            position: sticky;
            padding-bottom: 5px;
            width: 85%;
        }

    
    }

    .container-hints {
        display: inline-block;
        height: 100px;
    }

    .no-solution-hint {
        width: 184px;
    }

    /* this is only for assets */
    .js-crypto-payment2 {
        display: grid;

        h4 {
            margin-top: 6px !important;
        }

        .media-body p {
            word-break: break-word;
        }

        .person {
            width: 110px !important;
            padding: 0;
        }

        .browse-media-body {
            height: 110px;
        }

        .person__image {
            width: 90px;
            height: 90px;
        }

        .browse-media-vote {
            display: none !important;
        }

        .fishing-net3 {
            position: absolute;
            top: 27px;
            right: -5px;
            width: 64px;
            height: 60px;
        }
    }

}

@include screen-sm() {

    .water-mark {
        display: none;
    }

    .not-for-mobile {
        display: none !important;
    }

    .only-devices {
        display: block !important;
        height: 1px;
    }

    .paddings {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .crypto-image {
        img {
            height: 100%;
        }
    }

    .personTalk {
        margin: 7px 7px 5px 11px !important;
        padding: 0px !important;
        width: 29px;
    }

    .mobileP {
        text-align: center !important;
        font-size: 13px !important;
        margin: 3px 4px 3px 4px !important;
    }
    .upload-button {
        left: 2px;
    }

    .badges {
        display: inline-block;
    }

    .dingLike_city {
        z-index: 3;

        p {
            padding-bottom: 2px;
        }
    }

    .ding-style {
        height: auto;
        margin: 15px auto;
    }

    .ding-send {
        margin-bottom: 15px;
    }

    .ding-user-image {
        display: none;
    }

    /* RELATED TO MOBILE USER'S IMAGE */


    .roleplay-remodal-inside {
        img {
            width: 100%;
        }
    }

    .crypto-image {
        img {
            height: 100%;
        }
    }

    img {
        max-width: max-content;
    }

    /* This is for some dings */
    .imgMobile {
        width: 40px !important;
        height: 40px !important;
    }

    /* This is for other dings that use this class */
    .image-mobile {
        display: block;
        margin-top: 40px;
        margin-right: 4px;
    }

    .person__image {
        width: 40px;
        height: 40px;
    }

    .person__image2 {
        width: 40px;
        height: 40px;
    }

    .person {
        width: 45px !important;
        padding: 0;
    }

    /* this is only for assets */
    .js-crypto-payment2 {
        display: grid;

        h4 {
            margin-top: 6px !important;
        }

        .media-body p {
            word-break: break-word;
        }

        .person {
            width: 110px !important;
            padding: 0;
        }

        .browse-media-body {
            height: 110px;
        }

        .person__image {
            width: 90px;
            height: 90px;
        }

        .browse-media-vote {
            display: none !important;
        }

        .fishing-net3 {
            position: absolute;
            top: 27px;
            right: -5px;
            width: 64px;
            height: 60px;
        }
    }

    .person3 {

        margin: 0px !important;
        padding: 0px !important;
        display: inline-flex;
        width: auto !important;

        .imgMobile {
            width: 30px !important;
            height: 30px !important;
            margin-right: 5px;
        }

        h4 {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            margin: 0;
        }
    }

    .person__languages {
        display: none;
    }

    /* GENERAL STYLES FOR MOBILE */

    .mobile {
        margin-top: 6px;
        display: inline-block;
        padding-top: 10px;
        padding-bottom: 5px;
    }

    /* STYLES FOR TALK DINGS IN MOBILE */

    .ding-talk-mobile {
        display: flex;
        width: 100%;
    }

    .delete-ding {
        right: 25px;
    }

    .noEvents-team {
        font-size: 15px;
    }

    .team {
        margin-top: 4px !important;
    }

    .city__facebook-banner {
        font-size: 12px !important;
    }

    .fishing-net {
        display: none;
    }

    .fishing-net2 {
        right: -12%;
    }


    .exchange {
        width: 95%;
        min-height: auto;
    }

    .container-exchange {
        max-width: 551px;
        border-radius: 25px;
        box-shadow: 2px 2px 6px #dcdbdba3;
    }

    .container-exchange-like {
        display: inline-grid !important;
        position: initial;
        height: 1px;

        .container-ex-bubble {
            position: absolute;
            right: 0px;
            bottom: 0px;
            width: 74px;

            .dingLike_city {
                bottom: 22px;
            }
        }

        .fishing-net4 {
            cursor: pointer;
            background-color: #eee;
            width: 67px;
            height: 60px;
            position: absolute;
            right: 0px;
            bottom: 15px;
            border-radius: 65px 0px 0px 65px;
            z-index: 1;
        }
    }

    .container-exchange-user {
        height: auto;
        display: inline-flex;
    }

    .container-exchange-text {

        width: 100%;
        margin: 0px 0px 12px 6px;
        justify-content: flex-start;
        text-align: left;
        justify-content: initial;

        h3 {
            text-align: center;
            margin: 0;        
        }
        p {
            margin-left: 6px;
        }

    }

    .container-exchange-filters {
        display: inline-table;
        width: auto;
        height: auto;
    }

    .container-talk-like {
        display: block !important;
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 1px;

        .container-ex-bubble {
            position: absolute;
            right: 10px;
            bottom: 0px;
            padding-bottom: 10px;
            width: 20%;

            .dingLike_city {
                width: 45px;
                height: 45px;
                border-radius: 100%;
                border: 5px solid #ff609f;
                position: absolute;
                bottom: 16px;
                z-index: 3;
                right: 15px;
            }
        }

        .fishing-net5 {
            cursor: pointer;
            background-color: #eee;
            position: absolute;
            width: 88px;
            height: 58px;
            bottom: 9px;
            right: -2%;
            border-radius: 30px 0px 0px 30px;
            z-index: 1;
        }
    }

    .container-exchange-imageText {
        margin-bottom: 0px;
        display: flow-root;
    }

    .fishing-net6 {
        display: block !important;
        cursor: pointer;
        background-color: #eee;
        position: absolute;
        width: 101px;
        height: 86px;
        bottom: -6px;
        right: -4%;
        border-radius: 65px 70px;
        z-index: -1;
    }

    .ding-send2 {
        right: -1px;
    }

    .location i {
        margin-right: 5px;
    }
    .roaming i {
        margin-right: 5px;
    }

    .ding-bar {
        padding: 0px 0px 0px 11px !important;
        width: 100%;
        overflow: hidden;
    }


    .ex-img {
        border-radius: 0px;
    }

    .ex-txt {
        margin: 7px 0px 0px 10px;
        width: 80%;
    }

    .media-right-content {
        display: none;
    }

    .button-send {
        width: 60px;
        margin: 0;
        padding: 0;
        position: absolute;
        bottom: -3px;
        right: 10px;
        height: auto;
    }

    .fix-bar {
        width: 100%;
    }

    .father-subject {
        display: inline-block;
    }

    .flex {
        display: inline-block;
    }

    .hide-if {
        display: none;
    }

    .media-middle {
        vertical-align: bottom;
    }



    .upper-icons {
        display: inline-flex;
    }
    .bottom-icons {
        display: none !important;
    }

    .bottom-icons2 .js-icon-browse {
        display: none !important;
    }

    .bottom-icons2 .js-city-name {
        display: none !important;
    }

    .mini-icon-text {
        margin-bottom: 4px;
        font-size: 0.8rem;
    }

    .button_back {
        margin-bottom: 6px;
        justify-content: initial;

        p {
            margin-top: 3px;
        }
    }

}

/* OTHER (IMPORTANT) */

@media (min-width: 1200px) {
    .container {
        max-width: 600px !important;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 590px !important;
    }
    .ding-search2 .result .result-input {
        font-size: 1.12rem;
    }
}

@media (max-width: 992px) {
    .water-mark {
        top: 70px !important;
        right: 50px !important;
    }
}

@media (min-width: 768px) {
  .container {
    max-width: 650px !important;
  }

}

@media (max-width: 768px) {
    .water-mark {
        top: -97px !important;
        right: 42% !important;
    }
}

@media (min-width: 576px) {
  .container {
    max-width: 600px !important;
    padding: 0px 0px !important;
  }
}

@media (max-width: 576px) {
    .water-mark {
        top: -97px !important;
        right: 37% !important;
    }
}


@include screen-sm() {
    .row {
        padding: 0;
        margin-left: 0px;
        margin-right: 0px;
    }
}

@include screen-xs() {
    .row {
        padding: 0;
        margin-left: 0px;
        margin-right: 0px;
    }
}
